import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../utility/constants';
import axios from 'axios';
import { getCurrentUTCTimestamp } from '../../utility/utils';

const CallbackPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const returnToReport = localStorage.getItem('returnToReport') === 'true';
  useEffect(() => {
    const getIdToken = async () => {
      try {
        const hashParams = new URLSearchParams(location.hash.substring(1));
        const authToken = hashParams.get('id_token');
        
        if (authToken) {
          const tokenTime = getCurrentUTCTimestamp();
          parseJwt(authToken, tokenTime);
        } else {
          console.error('No authorization code found in URL');
        }
      } catch (error) {
        console.error('Error handling callback:', error);
      }
    };
    const parseJwt = (authToken, tokenTime) => {
      try {
        const base64Url = authToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
        let oldUserDetails = (JSON.parse(localStorage.getItem("userDetails")) === null || JSON.parse(localStorage.getItem("userDetails")) === undefined) ? {} : JSON.parse(localStorage.getItem("userDetails"))
        oldUserDetails["userid"] = JSON.parse(jsonPayload).email;
        oldUserDetails["authToken"] = authToken;
        oldUserDetails["tokenTime"] = tokenTime;
        localStorage.setItem("userDetails", JSON.stringify(oldUserDetails));
      } catch (error) {
        console.error('Error parsing JWT:', error);
        return null;
      }
    };
    const getCustomerId = async () => {
      let oldUserDetails = JSON.parse(localStorage.getItem("userDetails"))
      try {
        const response = await axios.get(API_URL+"user/"+oldUserDetails.userid, {
          headers: {
            "Auth-Token": oldUserDetails.authToken
          }
        });
        if(response.data.name){
          let newUserDetails = {
            customerid: response.data.customerid,
            userid: oldUserDetails.userid,
            businessname: response.data.businessname,
            city: response.data.city,
            country: response.data.country,
            designation: response.data.designation,
            name: response.data.name,
            phone: response.data.phone,
            role: response.data.role,
            state: response.data.state,
            authToken: oldUserDetails.authToken,
            tokenTime: oldUserDetails.tokenTime,
            industry: response.data.industry,
            employeecount: response.data.employeecount,
            ispremium: response.data.ispremium
          };
          localStorage.setItem("userDetails", JSON.stringify(newUserDetails));
          if(returnToReport){
            await postAnswers(newUserDetails);
          }else{
            navigate('/home');
          }
        }else{
          navigate('/userprofile');
        }
        
      } catch (error) {
        console.error('Error:', error);
      } finally{
      }
  
    };

    getIdToken();
    getCustomerId();
  }, [location, navigate]);
  const postAnswers = async (newUserDetails) => {
    const answersToPost = JSON.parse(localStorage.getItem('userAnswer'));
      const answersToPostWithUserId = answersToPost.map(item => ({
        ...item,
        userid: newUserDetails.userid,
        customerid: newUserDetails.userid,
        industry: newUserDetails.industry,
        employeecount: newUserDetails.employeecount,
      }));
      try {
        const response = await axios.post(API_URL+"bulk/qna", {
          bulkQnA: answersToPostWithUserId
        },
        {
          headers: {
            "Auth-Token": newUserDetails.authToken
        }
        });
        if(response.status===200){
          localStorage.removeItem('returnToReport');
          localStorage.removeItem('userAnswer');
          navigate('/report');
        }else{
        }
          
      } catch (error) {
        console.error('Error:', error);
      } finally{
      }
  };
  return <div>Processing...</div>;
};

export default CallbackPage;
import React, { useState,  useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { API_URL, stripeCustomerPortal } from '../../utility/constants';
import { getUserDetails } from '../../utility/utils';
import Spinner from 'react-bootstrap/Spinner';


function SubscriptionDtl() {
  document.title = "Subscription | Firminy Solutions, LLC";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isUserPremium, setIsUserPremium] = useState(false);
  const [isSubscriptionOwner, setSubscriptionOwner] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const checkIfUserRegistered = async () => {
      const usrDtl = getUserDetails(dispatch);
      if(usrDtl.role===undefined || usrDtl.role===null || usrDtl.role===""){
        navigate('/home');
      }
    };
    const checkUserPremium = async () => {
      const usrDtl = getUserDetails(dispatch);
      if(usrDtl.ispremium && usrDtl.ispremium === "yes"){
        setIsUserPremium(true);
        if(usrDtl.role!==undefined && usrDtl.role==="Subscription Owner"){
          setSubscriptionOwner(true);
        }else{
          setSubscriptionOwner(false);
        }
      }else{
        setLoading(true);
        for(let i=0;i<8;i++){
          let resp = await getCustomerId();
          if(resp.ispremium && resp.ispremium === "yes"){
            setIsUserPremium(true);
            if(resp.role!==undefined && resp.role==="Subscription Owner"){
              setSubscriptionOwner(true);
            }else{
              setSubscriptionOwner(false);
            }
            window.location.reload();
            break;
          }else{
            setIsUserPremium(false);
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
        }
        setLoading(false);
      }
    };
    checkIfUserRegistered();
    checkUserPremium();
  }, []);
  const openStripeAccount = () => {
    window.location.href=stripeCustomerPortal;
  };
  const startAssessment = () => {
    let userDetails = JSON.parse(localStorage.getItem("userDetails"));
      if(userDetails.industry!==undefined && userDetails.employeecount!==undefined){
        navigate('/assessment');
      }else{
        navigate('/choosebusiness');
      }
  };
  const getCustomerId = async () => {
    let oldUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    try {
      const response = await axios.get(API_URL+"user/"+oldUserDetails.userid, {
        headers: {
          "Auth-Token": oldUserDetails.authToken
        }
      });
      if(response.data.name){
        let newUserDetails = {
          customerid: response.data.customerid,
          userid: oldUserDetails.userid,
          businessname: response.data.businessname,
          city: response.data.city,
          country: response.data.country,
          designation: response.data.designation,
          name: response.data.name,
          phone: response.data.phone,
          role: response.data.role,
          state: response.data.state,
          authToken: oldUserDetails.authToken,
          tokenTime: oldUserDetails.tokenTime,
          industry: response.data.industry,
          employeecount: response.data.employeecount,
          ispremium: response.data.ispremium
        };
        localStorage.setItem("userDetails", JSON.stringify(newUserDetails));
        return newUserDetails;
      }else{
        return {};
      }
      
    } catch (error) {
      console.error('Error:', error);
    } finally{

    }

  };

  return (
    <div>
      <section className="step-wrap pt-5 relative z-10 relative pb-10 lg:pb-20">
      {loading ? (
        <Spinner style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) :(
        <div>
          <img alt="" className="floating-bg absolute w-auto left-1/2 -translate-x-1/2 bottom-0 hidden lg:block" src="images/gradient-bg.png"/>
          <img alt="" className="floating-bg absolute w-full left-1/2 -translate-x-1/2 bottom-0 lg:hidden opacity-50"
            src="images/gradient-bg-mobile.png"/>
          <div className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40 relative z-10">

            <div className="select-business-category bg-black rounded-xl border-stone-600 border-solid border">
              <div className="select-business-top py-8 px-4 md:py-10 md:px-10">
                <div className="banner-steps flex relative w-full py-0 md:py-10 items-center flex-wrap">
                  <div className="banner-icon-box relative w-full">
                    {isUserPremium===true? (
                      <span className="banner-icon hand-cursor flex items-center justify-center w-28 h-28 rounded-full mx-auto transition ease-in-out duration-700 rounded-full">
                      <img alt="" className="inline-block" src="images/success.svg" />
                    </span>
                    ):(
                      <span className="banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-secondary rounded-full mx-auto transition ease-in-out duration-700 rounded-full cursor-pointer">
                        <img alt=""
                          className="inline-block w-6 md:w-8"
                          src="images/cross-no.svg"/>
                      </span>
                    )}
                    
                  </div>
                </div>


                <div className="banner-steps flex relative w-full py-8 md:py-8 items-center flex-wrap">
                  <div className="relative w-full lg:w-full flex-auto text-white text-center">
                    {isUserPremium===true? (
                    <h5 style={{fontSize: '2rem'}} className="text-xl md:text-2xl lg:text-2xl xl:text-2xl font-primary font-normal text-white text-center leading-tight mb-1 mt-1">You have an active subscription plan!</h5>             
                    ): (
                      <h5 style={{fontSize: '3rem'}} className="text-xl md:text-2xl lg:text-2xl xl:text-2xl font-primary font-normal text-white text-center leading-tight mb-1 mt-1">You do not have any active subscription plan!</h5>             
                    )}
                    </div>
                </div>
                {isSubscriptionOwner===true? (
                <div className="banner-steps flex relative w-full py-2 md:py-4  items-center text-center flex-wrap">
                  <div className="w-full md:w-full lg:w-full flex-auto text-white text-right button-box flex justify-center py-0">
                    <button onClick={startAssessment} className="text-black custom-btn-primary text-center text-l sm:text-xl flex items-center justify-center py-4 px-8 transition ease-in-out duration-700 rounded-full">
                    Cybersecurity Assessment
                    </button>
                  </div>
                  </div>
                ):(null)}
                {isSubscriptionOwner===true? (
                <div className="banner-steps flex relative w-full py-2 md:py-4  items-center text-center flex-wrap">

                  <div className="w-full md:w-full lg:w-full flex-auto text-white text-center button-box flex justify-center py-2">
                    <button onClick={openStripeAccount} className="text-black custom-btn-primary text-left text-l sm:text-xl flex items-center justify-center py-4 px-8 transition ease-in-out duration-700 rounded-full">
                      Manage Subscription
                      <img alt="" className="inline-block ml-4" src="images/button-arrow-right.svg"/>
                    </button>
                  </div>
                </div>
                ):(null)}
                  
              </div>
            </div>
          </div>
        </div>
      )}
      </section>
    </div>
  );
}
export default SubscriptionDtl;
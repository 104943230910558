import React, { useState,  useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAlert } from 'react-alert'
import axios from 'axios';
import { API_URL, env } from '../../utility/constants';
import { getUserDetails } from '../../utility/utils';

function QnsComplete() {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  let storedAnswer = [...useSelector(state => state.storedAnswers)];
  const [score, setScore] = useState([0,0,0,0]);
  const [isUserPremium, setIsUserPremium] = useState(false);
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  useEffect(() => {
    document.title = "Report | Firminy Solutions, LLC";
    let usrDtl = getUserDetails(dispatch);
    const getScore = async () => {
      let answersToPost = storedAnswer;
      let tempScore = [0, 0, 0, 0]
      for (let i = 0; i < answersToPost.length; i++) {
        tempScore[0] = tempScore[0] + 1;
        if(answersToPost[i]["answeroption"]!==undefined && answersToPost[i]["answeroption"]!==null && answersToPost[i]["answeroption"].toLowerCase() === "yes"){
          tempScore[1] = tempScore[1] + 1;
        }else if(answersToPost[i]["answeroption"]!==undefined && answersToPost[i]["answeroption"]!==null && answersToPost[i]["answeroption"].toLowerCase() === "no"){
          tempScore[2] = tempScore[2] + 1;
        }else{
          tempScore[3] = tempScore[3] + 1;
        }
      }
      setScore(tempScore);
    };
    const checkUserPremium = async () => {
      if(usrDtl.ispremium !== undefined && usrDtl.ispremium === "yes"){
        setIsUserPremium(true);
      }
    };
    const checkIfUserRegistered = async () => {
      if(usrDtl.name !== undefined){
        setIsUserRegistered(true);
      }else{
        setIsUserRegistered(false);
      }
    }
    checkIfUserRegistered();
    getScore();
    checkUserPremium();
  }, []);
  const openLogin = async () => {
    await getLoginURL();
  };
  const getLoginURL = async () => {
    const response = await axios.get(API_URL+"getloginurl");
    if(response.data){
      window.location.href = response.data[env];
    }else{
      console.log("Some Error occurred");
    }
  };
  const openReport = () => {
    localStorage.removeItem('userAnswer')
    navigate('/report');
  };
  const openSubscription = () => {
    localStorage.removeItem('userAnswer');
    let usrDtl = getUserDetails(dispatch);
    if(usrDtl.role && usrDtl.role==="Registered User"){
      navigate('/subscription');
    }else{
      alert.info("Ask your Account owner to subscribe");
    }
  };

  return (
    <div>
      <section className="step-wrap pt-5 relative z-10 relative pb-10 lg:pb-20">
        <img alt="" className="floating-bg absolute w-auto left-1/2 -translate-x-1/2 bottom-0 hidden lg:block" src="images/gradient-bg.png"/>
        <img alt="" className="floating-bg absolute w-full left-1/2 -translate-x-1/2 bottom-0 lg:hidden opacity-50"
          src="images/gradient-bg-mobile.png"/>
        <div className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40 relative z-10">
          <p className="text-[#767676] inline-block font-primary font-semibold text-lg mb-6">
            Step 02 Completed
          </p>

          <div className="select-business-category bg-black rounded-xl border-stone-600 border-solid border">
            <div style={isUserRegistered ? null : { height: '500px' }} className="select-business-top py-8 px-4 md:py-10 md:px-10 border-stone-600 border-solid border-b">
              <div className="banner-steps flex relative w-full py-0 md:py-10 items-center flex-wrap">
                <div className="relative w-full lg:w-2/3 flex-auto">
                  <h5 className="text-3xl lg:text-5xl font-primary font-semibold text-white text-left lg:text-left leading-tight mb-4">
                  Well Done!
                  </h5>
                  <p className="text-xl font-primary font-normal text-white text-left lg:text-left leading-normal mb-6 lg:mb-0">
                    <span className="custom-text-primary"> {score[1]} out of {score[0]} &nbsp;</span> 
                    basic requirements met! Protect your business by completing a detailed cybersecurity assessment with actionable insights and tools to secure your business.
                  </p>
                </div>
                <div className="flex relative w-full lg:w-1/3 flex-auto">
                  <div className="w-full text-center">
                    <div className="button-box flex justify-center">
                    {!isUserRegistered &&  (
                      <button onClick={() => openLogin()} className="button-warning text-black custom-btn-primary text-sm md:text-xl flex items-center justify-center font-bold py-4 px-8 transition ease-in-out duration-700 rounded-full hover:custom-bg-primary">
                        Login / Sign up to View Report
                      </button>
                    )}
                    {isUserRegistered &&  (
                      <button onClick={() => openReport()} className="button-warning text-black custom-btn-primary text-sm md:text-xl flex items-center justify-center font-bold py-4 px-8 transition ease-in-out duration-700 rounded-full hover:custom-bg-primary">
                        View Detailed Report
                      </button>
                    )}
                    </div>
                    {/* <p className="text-center mt-4">
                      <a className="text-lg font-normal underline text-[#767676] transition ease-in-out duration-700 hover:text-primary">
                        Continue Free Audit
                      </a>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            {!isUserPremium && isUserRegistered &&  (
            <div className="select-business-bottom py-8 px-4 md:py-10 md:px-10">
              <div className="banner-steps flex relative w-full py-0 md:py-10 items-center flex-wrap">
                <div className="relative w-full lg:w-2/3 flex-auto">
                  <h6 className="text-2xl font-primary font-semibold custom-text-primary text-left lg:text-left leading-tight mb-2">
                  Cybersecurity Awareness and Assessment Service:
                  </h6>
                  <ul>
                    <li className="bullet-li text-xl font-primary font-normal text-white text-left lg:text-left leading-tight mb-6 lg:mb-0">
                      a detailed assessment of your cybersecurity posture

                    </li>
                    <li className="bullet-li text-xl font-primary font-normal text-white text-left lg:text-left leading-tight mb-6 lg:mb-0">
                      training your employees to detect phishing and ransomware threats                  

                    </li>
                    <li className="bullet-li text-xl font-primary font-normal text-white text-left lg:text-left leading-tight mb-6 lg:mb-0">
                      prepare you with an incident response plan in case of ransomware attack                  

                    </li>

                  </ul>
                </div>
                <div className="flex relative w-full lg:w-1/3 flex-auto">
                  <div className="w-full text-center">
                    <div className="button-box flex justify-center">
                      <button onClick={() => openSubscription()} className="button-primary text-black custom-btn-secondary text-sm md:text-xl flex items-center justify-center font-bold py-4 px-8 transition ease-in-out duration-700 rounded-full hover:bg-secondary">
                        <img alt="" className="inline-block mr-4" src="images/lock.svg" />
                        Protect Your Business Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
export default QnsComplete;
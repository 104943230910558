import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { API_URL, env } from '../utility/constants';
import { useAlert } from 'react-alert';
import { storeAnswers } from '../services/actions';
import { getUserDetails } from '../utility/utils';

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let userDetails = getUserDetails(dispatch);
  const alert = useAlert();
  const [isOpen, setIsOpen] = useState(false);
  const [loginURL, setLoginURL] = useState("");
  useEffect(() => {
    const checkAuthId = async () => {
      if(userDetails.userid!==undefined){
        let resp = await getCustomerId()
        if(resp.name!==undefined && resp.authToken === undefined){
          openLogout();
        }else{
          userDetails = resp;
        }
      }
    }
    const getLoginURL = async () => {
      const response = await axios.get(API_URL+"getloginurl");
      if(response.status===200){
        setLoginURL(response.data[env]);
      }else{
        console.log("Some Error occurred");
      }
    };
    checkAuthId();
    getLoginURL();
  },[]);
  const subscribe = async () => {
    setIsOpen(false);
    if(userDetails.role && userDetails.role==="Subscription Owner"){
      navigate('/subscriptiondtl');
    }else if(userDetails.role && userDetails.role==="Registered User"){
      navigate('/subscription');
    }else{
      if(userDetails.role === "Subscription Support"){
        alert.info("Only Subscription Owner can view this page");
      }else{
        alert.info("Only Registered User can subscribe");
      }
    }
  };
  const handleGoogleLogin = () => {
    window.location.href = loginURL;
  };
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const goToHome = () => {
    setIsOpen(false);
    navigate('/home');
  };
  const openLogin = () => {
    setIsOpen(false);
    handleGoogleLogin();
  };
  const openReport = () => {
    setIsOpen(false);
    navigate('/report');
  };

  const openAssessment = () => {
    setIsOpen(false);
    navigate('/choosebusiness');
  };
  const openProfile = () => {
    setIsOpen(false);
    navigate('/userprofile');
  };
  const openLogout = () => {
    localStorage.removeItem("userDetails");
    userDetails = {};
    dispatch(storeAnswers([]));
    setIsOpen(false);
    alert.success("Logout Successful!");
    navigate('/home');
  };
  const getCustomerId = async () => {
    let oldUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    try {
      const response = await axios.get(API_URL+"user/"+oldUserDetails.userid, {
        headers: {
          "Auth-Token": oldUserDetails.authToken
        }
      });
      if(response.data.name){
        let newUserDetails = {
          customerid: response.data.customerid,
          userid: oldUserDetails.userid,
          businessname: response.data.businessname,
          city: response.data.city,
          country: response.data.country,
          designation: response.data.designation,
          name: response.data.name,
          phone: response.data.phone,
          role: response.data.role,
          state: response.data.state,
          authToken: oldUserDetails.authToken,
          tokenTime: oldUserDetails.tokenTime,
          industry: response.data.industry,
          employeecount: response.data.employeecount,
          ispremium: response.data.ispremium
        };
        localStorage.setItem("userDetails", JSON.stringify(newUserDetails));
        return newUserDetails;
      }else{
        return oldUserDetails;
      }
      
    } catch (error) {
      console.error('Error:', error);
    }

  };
  const openUserInvitation = async () => {
    setIsOpen(false);
    navigate('/inviteuser');
  }
  return (
    <header className="header pt-4 sm:pt-4 md:pt-5 lg:pt-5 xl:pt-7 2xl:pt-9 relative z-20">
    <div className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40">
      <div className="header-row flex pb-4 border-b border-slate-700 border-solid justify-between items-center">
        <div className="logo pr-8">
          <button onClick={goToHome}>
            <img alt="firminy-logo" className="block" src="images/firminy-logo.svg" />
            <span className="block text-xs text-gray-300 font-primary font-normal">
              Protecting Businesses Against Cybersecurity Threats
            </span>
          </button>
        </div>
        <div className="login-sign-hemburger flex items-center justify-end">
          <ul className="login-sign hidden items-center md:pr-8 lg:pr-20 sm:hidden md:flex">
            <li className="mr-6 pr-6 border-solid border-r border-white leading-none">
            {userDetails.name ? (
              <button onClick={openProfile} className="text-white font-primary font-normal text-base leading-none hover:text-primary transition ease-in-out duration-500">{userDetails.name}</button>
              ) : (
              <button onClick={openLogin} className="text-white font-primary font-normal text-base leading-none hover:text-primary transition ease-in-out duration-500">Login</button>
            )}
            </li>
            <li className={userDetails.name ? "mr-6 pr-6 border-solid border-r border-white leading-none" : "leading-none"}>
            {userDetails.ispremium==="yes" ? (
              <button onClick={subscribe} className="text-white font-primary font-normal text-base leading-none hover:text-primary transition ease-in-out duration-500"><span className="custom-text-primary"> Premium </span></button>
              ) : (
              null
            )}
            {userDetails.name && (userDetails.ispremium==="no") ? (
              <button onClick={subscribe} className="text-white font-primary font-normal text-base leading-none hover:text-primary transition ease-in-out duration-500">Subscribe</button>
              ) : (
                null
            )}
            </li>
            <li className="leading-none">
            {userDetails.name ? (
              <button onClick={openLogout} className="text-white font-primary font-normal text-base leading-none hover:text-primary transition ease-in-out duration-500">Logout</button>
              ) : (
              <button onClick={openLogin} className="text-white font-primary font-normal text-base leading-none hover:text-primary transition ease-in-out duration-500">Register</button>
            )}
            </li>
          </ul>
          <span className="hemburger cursor-pointer">
            <img onClick={toggleSidebar} alt="menu-hemburger" className="block" src="images/menu-hemburger.svg" />
          </span>
        </div>
      </div>
    </div>

    <div style={{overflowY: 'auto'}} className={isOpen? "sidebar-menu fixed right-0 top-0 sm:w-96 w-4/5 h-screen flex justify-center items-center custom-bg-primary transition ease-in-out duration-700" : "sidebar-menu fixed right-0 top-0 sm:w-96 w-4/5 h-screen flex justify-center items-center custom-bg-primary transition ease-in-out duration-700 translate-x-96"}>
      <div className="sider-close absolute top-4 right-4 cursor-pointer">
        <img onClick={toggleSidebar} alt="close" className="banner-arrow-top" src="images/close.webp" />
      </div>
      <div className="sidebar-menulist-wrap">
      {userDetails.name ? (
          <div className="w-full flex items-center p-2 md:p-5">
            <div className="flex-auto pl-2 lg:pl-6 border-l-2 border-slate-300 border-solid w-4/12 lg:w-3/12">
              <p className="text-xs md:text-sm lg:text-lg leading-tight font-primary text-black font-normal text-left">
                <strong className="block"> {userDetails.name} {userDetails.ispremium==="no" ? "": "(Premium)"} </strong>
              </p>
              <p className="text-xs md:text-sm lg:text-lg leading-tight font-primary text-black font-normal text-left">
                {userDetails.designation}
              </p>
              <h5 className="text-xs md:text-lg lg:text-2xl leading-tight font-primary text-black font-bold my-1 lg:my-3">
                {userDetails.businessname}
              </h5>
              <p className="text-xs md:text-sm lg:text-lg leading-tight font-primary text-black font-normal">
              {userDetails.city}, {userDetails.state}, {userDetails.country}
              </p>
            </div>
          </div>
          ) : (
              null
        )}
        <ul style={{marginLeft: '40px'}} className="sidebar-menulist text-left">
          <li className="">
            <button onClick={goToHome} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>Home</h5></button>
          </li>
          {userDetails.name &&( 
          <li>
            <button onClick={openReport} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>Report</h5></button>
          </li>)}
          <li>
            <button onClick={openAssessment} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>Assessment</h5></button>
          </li>
          {
            userDetails.role==="Subscription Owner" && (
          <li>
            <button onClick={openUserInvitation} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>Invite User</h5></button>
          </li>
          )}
          {userDetails.ispremium==="no" && (          
          <li>
            <button onClick={subscribe} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>Subscribe</h5></button>
          </li>)}
          {userDetails.ispremium==="yes" && (          
          <li>
            <button onClick={subscribe} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>Subscription</h5></button>
          </li>)}
          
          {userDetails.name &&( 
          <li>
            <button onClick={openProfile} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>Profile</h5></button>
          </li>)}
          <li>
            <button onClick={toggleSidebar} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>Contact</h5></button>
          </li>
          <li>
            <button onClick={toggleSidebar} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>About Us</h5></button>
          </li>
          {!userDetails.name &&(          
          <li>
            <button onClick={openLogin} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>Login</h5></button>
          </li>)}
          {!userDetails.name &&( 
          <li>
            <button onClick={openLogin} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>Signup</h5></button>
          </li>)}
          {userDetails.name &&(          
          <li>
            <button onClick={openLogout} className="sidebar-menuitem py-2 inline-block transition ease-in-out duration-700 hover:text-white text-2xl"><h5>Logout</h5></button>
          </li>)}

        </ul>
      </div>
    </div>
    </header>
  );
}
export default Header;
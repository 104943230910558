import { storeAnswers } from "../services/actions";


export const logoutAlertOptions = {
    timeout: 5000, // Hide after 5 seconds
    offset: '30px', // Set the offset from the top
    position: 'center', // Center the alert
    type: 'info', // Set the alert type (info, success, error)
    containerStyle: {
      fontSize: '24px', // Set the font size
      padding: '20px', // Set the padding
      borderRadius: '10px', // Set the border radius
      width: '50%', // Set the width
      textAlign: 'center', // Center the text
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.5)', // Add a shadow
      background: '#f2f2f2', // Set the background color
    },
    overlayStyle: {
      zIndex: 1000, // Set the z-index
    },
};
export const getCurrentUTCTimestamp = () => {
    const currentDate = new Date();
    const currentUTCTimestamp = currentDate.toISOString();
    return currentUTCTimestamp;
};

export const getUserDetails = (dispatch) => {
    const userDetails = ((JSON.parse(localStorage.getItem("userDetails")) === null) || (JSON.parse(localStorage.getItem("userDetails")) === undefined))? {} : JSON.parse(localStorage.getItem("userDetails"));
    if(userDetails.authToken===undefined || userDetails.authToken===null){
        localStorage.removeItem("authToken");
        return userDetails;
    }else{
        const milliseconds = Date.parse(userDetails.tokenTime);
        const idTokenUtcTimestamp = new Date(milliseconds);
        const currentUTCTimestamp = getCurrentUTCTimestamp();
        const idTokenInMillis = new Date(idTokenUtcTimestamp);
        const currentInMillis = new Date(currentUTCTimestamp);
        const differenceInMilliseconds = Math.abs(currentInMillis - idTokenInMillis);
        const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
        if(userDetails.authToken===null || userDetails.authToken === undefined || userDetails.tokenTime===undefined || userDetails.tokenTime===null || differenceInMinutes > 115){
            clearStore(dispatch);
            return {};
        }else{
            return userDetails;
        }
    }
    
};

export const clearStore = (dispatch) => {
    localStorage.removeItem("userDetails");
    dispatch(storeAnswers([]));
};
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../utility/constants';
import { useAlert } from 'react-alert'
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../../utility/utils';

function InviteUser() {
  document.title = "Invite User | Firminy Solutions, LLC";
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inviteUser, setInviteUser] = useState({"invitedusername": "", "inviteduseremail": "", "inviteduserdesig": "SE", "inviteduserrole": ""});
  const [selectedOption, setSelectedOption] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const options = ["Subscription Support"];
  //Subscription Member add later

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    inviteUser.inviteduserrole = option;
    setIsOpen(false);
  };
  const handleDocumentClick = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    const userDetails = getUserDetails(dispatch);
    if(userDetails.authToken === undefined){
      navigate('/home');
      alert.info("Your Session is Expired. Please Log In Again.");
    }
    if(userDetails.role!=="Subscription Owner"){
        navigate('/home');
    }
    setInviteUser(prevState => ({
        ...prevState,
        souserid: userDetails.userid,
        customerid: userDetails.customerid
    }));
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };

  },[]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInviteUser(prevState => ({
      ...prevState,
      [name]: value
    }));

  };

  const inviteUserToJoin = async () => {
    const usrDtl = getUserDetails(dispatch);
    if(inviteUser.invitedusername === "" || inviteUser.inviteduseremail === ""){
        alert.error("Please Fill All Details");
    }
    else if(selectedOption === ""){
        alert.error("Please Select Role");
    }else{
        setLoading(true);
        try {
            const response = await axios.post(API_URL+"inviteusertosubscription", inviteUser,
              {
                headers: {
                  "Auth-Token": usrDtl.authToken
                }
              }
            );
            if(response){
                alert.success("Invitation Sent Successfully!");
            }
        } catch (error) {
            if(error.response.data[1].resultstring !== undefined && error.response.data[1].resultstring !== ''){
                alert.error(error.response.data[1].resultstring);
            }else{
                alert.error("Some Unknown Error Occurred!")
            }
            console.error('Error:', error);
        } finally{
            setLoading(false);
        }
    }

  };

  return (
    <div>

    <section className="step-wrap pt-8 xl:pt-14 relative z-10 relative pb-5 md:pb-10 xl:pb-20">
    {loading ? (
        <Spinner style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} animation="border" role="output">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) :(
        <div>
        <img alt="" className="floating-bg absolute w-auto left-1/2 -translate-x-1/2 bottom-0 hidden lg:block" src="images/gradient-bg.png"/>
        <img alt="" className="floating-bg absolute w-full left-1/2 -translate-x-1/2 bottom-0 lg:hidden opacity-50" src="images/gradient-bg-mobile.png"/>
        <div style={{height:'500px'}} className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40 relative z-10">
          <div className="select-business-category bg-black rounded-xl border-stone-600 border-solid border" >
            <div className="select-business-top p-4 md:p-10 border-stone-600 border-solid border-b">
              <h5 className="text-sm md:text-xl lg:text-2xl lg:text-4xl font-primary font-normal text-white text-center leading-tight mb-2">
              Invite a user to review the assessment
              </h5>
              <form>
                <div className="banner-steps flex relative w-full pt-5 lg:pt-10 items-center justify-between flex-wrap">
                  <div className="relative w-full lg:w-1/2 xl:w-1/2 flex-none mb-4 lg:mb-0 xl:pr-4">
                    <label className="flex">
                      <span className="flex flex-none items-end text-white text-sm md:text-lg lg:text-2xl font-primary font-normal pl-3 leading-none">
                        <sup className="text-red-500 absolute left-0 top-4 xl:top-2">*</sup>
                        <span className="flex items-end whitespace-nowrap pr-2">
                          Enter Name
                        </span>
                      </span>
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                        <input  type="text" id="invitedusername" name="invitedusername" value={inviteUser.invitedusername} onChange={handleChange} className="bg-transparent outline-none border-b border-solid border-stone-500 text-white text-sm lg:text-xl font-primary font-normal w-full"/>
                      </span>
                    </label>
                  </div>
                  <div className="relative w-full lg:w-1/3 xl:w-1/3 flex-none mb-4 lg:mb-0 xl:pr-4">
                    <label className="flex">
                      <span className="flex items-end text-white text-sm md:text-lg lg:text-2xl font-primary font-normal pr-4 pl-3 leading-none">
                        <sup className="text-red-500 absolute left-0 top-4 xl:top-2">*</sup>
                        <span className="flex items-end whitespace-nowrap">
                          Email
                        </span>
                      </span>
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                        <input type="email" id="inviteduseremail" name="inviteduseremail" value={inviteUser.inviteduseremail} onChange={handleChange} className="bg-transparent outline-none border-b border-solid border-stone-500 text-white text-sm lg:text-xl font-primary font-normal w-full text-center" placeholder="xyz@email.com"/>
                      </span>
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div className="select-business-bottom p-4 md:p-10">
              <form>
                <div className="banner-steps flex relative w-full pt-1 lg:pt-10 items-center flex-wrap">
                  {/* <div className="relative w-full lg:w-1/2 xl:w-1/2 flex-none mb-4 lg:mb-0 xl:pr-4">
                    <label className="flex">
                      <span className="flex items-end text-white text-sm md:text-lg lg:text-2xl font-primary font-normal pr-4 pl-3 leading-none">
                        <sup className="text-red-500 absolute left-0 top-4 xl:top-2">*</sup>
                        <span className="flex items-end whitespace-nowrap">
                          Enter Designation
                        </span>
                      </span>
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                        <input type="text" id="inviteduserdesig" name="inviteduserdesig" value={inviteUser.inviteduserdesig} onChange={handleChange} className="bg-transparent outline-none border-b border-solid border-stone-500 text-white text-sm lg:text-xl font-primary font-normal w-full"/>
                      </span>
                    </label>
                  </div> */}
                  <div className="relative w-full lg:w-1/2 xl:w-1/2 flex-none mb-4 lg:mb-0 xl:pr-4">
                    <label className="flex">
                      <span className="flex items-end text-white text-sm md:text-lg lg:text-2xl font-primary font-normal pr-4 pl-3 leading-none">
                        <sup className="text-red-500 absolute left-0 top-4 xl:top-2">*</sup>
                        <span className="flex items-end whitespace-nowrap">
                          Select Role
                        </span>
                      </span>
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                      <div className="custom-select bg-transparent outline-none border-b border-solid border-stone-500 text-white text-sm lg:text-xl font-primary font-normal w-full" ref={selectRef}  onClick={() => setIsOpen(!isOpen)}>
                            <div>{selectedOption || "Select an option"}</div>
                            {isOpen && (
                                <div className="options">
                                {options.map((option, index) => (
                                    <div key={index} className="option" onClick={() => handleOptionClick(option)}>
                                    {option}
                                    </div>
                                ))}
                                </div>
                            )}
                        </div>
                      </span>
                    </label>
                  </div>
                </div>
              </form>
              <div className="button-box flex my-3 md:my-6 justify-center">
                <button onClick={() => inviteUserToJoin()} className="button-primary text-black custom-btn-primary text-sm md:text-xl font-bold flex items-center text-center justify-center py-3 px-8 transition ease-in-out duration-700 rounded-full">
                  Invite User To Join
                  <img alt="" className="inline-block ml-4" src="images/button-arrow-right.svg"/>
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
      )}
      </section>
    </div>
  );
}
export default InviteUser;


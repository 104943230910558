export const STORE_FIRST_QUESTION = 'STORE_FIRST_QUESTION';
export const STORE_ORG_TYPE = "STORE_ORG_TYPE";
export const STORED_ANSWERS = 'STORED_ANSWERS';

export const storeFirstQuestion = (data) => ({
  type: STORE_FIRST_QUESTION,
  payload: data,
});


export const storeOrgType = (data) => ({
  type: STORE_ORG_TYPE,
  payload: data,
});



export const storeAnswers = (data) => ({
  type: STORED_ANSWERS,
  payload: data,
});
import React, { useState,  useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pricing_table_id, publishable_key} from '../../utility/constants';
import { getUserDetails } from '../../utility/utils';


function Subscription() {
  document.title = "Subscription | Firminy Solutions, LLC";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientReferenceId, setClientReferenceId] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    const checkIfUserRegistered = async () => {
      const usrDtl = getUserDetails(dispatch);
      if(usrDtl.role===undefined || usrDtl.role!=="Registered User"){
        navigate('/home');
      }
      setClientReferenceId(usrDtl.customerid);
      setEmail(usrDtl.userid);
    };
    checkIfUserRegistered();
  }, []);

  return (
    <div>
      <section className="step-wrap pt-5 relative z-10 relative pb-10 lg:pb-20">
        <img alt="" className="floating-bg absolute w-auto left-1/2 -translate-x-1/2 bottom-0 hidden lg:block" src="images/gradient-bg.png"/>
        <img alt="" className="floating-bg absolute w-full left-1/2 -translate-x-1/2 bottom-0 lg:hidden opacity-50"
          src="images/gradient-bg-mobile.png"/>
        <div className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40 relative z-10">

          <div className="select-business-category bg-black rounded-xl border-stone-600 border-solid border">
            <div className="select-business-top py-8 px-4 md:py-10 md:px-10 border-stone-600 border-solid border-b">
              <div className="banner-steps flex relative w-full py-0 md:py-10 items-center flex-wrap">
                <div className="relative w-full lg:w-full flex-auto">
                <stripe-pricing-table pricing-table-id={pricing_table_id}
                  publishable-key={publishable_key}
                  client-reference-id={clientReferenceId}
                  customer-email={email}
                >
                </stripe-pricing-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Subscription;
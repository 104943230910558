import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../messagemodal.css";
import Modal from 'react-bootstrap/Modal';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { API_URL } from '../../utility/constants';
import { useAlert } from 'react-alert';
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from 'react-date-picker';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import {storeAnswers} from '../../services/actions';
import { getUserDetails } from '../../utility/utils';

function StepQns() {
  document.title = "Assessment | Firminy Solutions LLP";
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const firstQuestion = useSelector(state => state.firstQuestion);
  const [currentQuestion, setCurrentQuestion] = useState(firstQuestion);
  let storedAnswer = [...useSelector(state => state.storedAnswers)];
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [mitigationShow, setMitigationShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [comment, setComment] = useState("");
  const [optionSelected, setOptionSelected] = useState(null);
  const [showOptional, setShowOptional] = useState(false);
  const [optionalChange, setOptionalChange] = useState(false);
  useEffect(() => {
    getStoredOptionSelected(currentQuestion);
  }, [navigate, currentQuestion]);
  const getStoredOptionSelected = (question) => {
    if(question && question.qid){
      for(let i = 0; i < storedAnswer.length; i++){
        if((storedAnswer[i].qid === question.qid) && (storedAnswer[i].answeroption !== undefined) && (storedAnswer[i].answeroption !== null)){
          setSelectedDate(storedAnswer[i].commentReminderDateAsISO8601);
          setComment(storedAnswer[i].userComment);
          setOptionSelected(storedAnswer[i].answeroption.toLowerCase());
          break;
        }
      }
    }
  };

  const handleDateChange = (date) => {
    setOptionalChange(true);
    setSelectedDate(date);
  };
  const handleCommentChange = (e) => {
    setOptionalChange(true);
    setComment(e.target.value);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleMitigationClose = () => setMitigationShow(false);
  const handleMitigationShow = () => setMitigationShow(true);
  
  const openAdditionalInfo = () => {
    window.open(currentQuestion.ahc, '_blank');
  };
  const yesNoButtonClick = async (type, isNext) => {
    setOptionSelected(type.toLowerCase());
    let answerOptionTemp = {};
      for (let i = 0; i < currentQuestion.answers[0].anserOptions.length; i++) {
        if(currentQuestion.answers[0].anserOptions[i].optiontext.toLowerCase() === type.toLowerCase()){
            answerOptionTemp = currentQuestion.answers[0].anserOptions[i];
        }
      }
      if(answerOptionTemp.optiontext){
        await postCurrentQuestionAnswer(answerOptionTemp, isNext);
        setShowOptional(false);
      }else{
        alert.error("Please choose option!");
      }
  };
  const postCurrentQuestionAnswer = async (ansopt, isNext) => {
    setLoading(true);
    await cacheAnswer(ansopt);
    let userDetails = getUserDetails(dispatch);
    if(userDetails.authToken!==undefined){
      try {
        const response = await axios.post(API_URL+"ans", {
              customerid: userDetails.customerid,
              userid: userDetails.userid,
              qid: currentQuestion.qid,
              aid: currentQuestion.answers[0].aid,
              premiumq: currentQuestion.premiumq,
              pl: currentQuestion.pl,
              category: currentQuestion.category,
              secimpact: currentQuestion.secimpact,
              answeroption: ansopt.optiontext,
              assessment: (ansopt.assessment !== null) ? ansopt.assessment :"",
              recommendation: (ansopt.recommendation !== null) ? ansopt.recommendation : "",
              userComment: (comment !== null) ? comment: "",
              setReminder: (selectedDate !== null && selectedDate!=="")? "yes" : "no",
              commentReminderDateAsISO8601: selectedDate
          },
          {
            headers: {
              "Auth-Token": userDetails.authToken
            }
          }
        );
        if(response.status === 200){
          alert.success("Successfully Stored");
          if(isNext){
            await getNextQuestion();
          }
        }else{
          alert.error("Some Error Occurred");
        }  
      } catch (error) {
        console.error('Error:', error);
      } finally{
        setLoading(false);
      }
    }else{
      try {
        if(isNext){
          await getNextQuestion();
        }      
      } catch(error){
        console.error('Error:', error);
      }finally{
        setLoading(false);
      }
      
    }
    
  };

  const getNextQuestion = async () => {
    let userDetails = getUserDetails(dispatch);
    if(userDetails.userid !== undefined){
      try {
        const response = await axios.post(API_URL+"q", {
            customerid: userDetails.customerid,
            userid: userDetails.userid,
            currquestionid: currentQuestion.qid,
            answerid: currentQuestion.answers[0].aid,
            industry: userDetails.industry,
            employeeCount: userDetails.employeecount
          },
          {
            headers: {
              "Auth-Token": userDetails.authToken
            }
          }
        );
        if(response.data.qid){
          setOptionSelected(null);
          setSelectedDate(null);
          setOptionalChange(false);
          setComment("");
          setShowOptional(false);
          setCurrentQuestion(response.data);
          getStoredOptionSelected(response.data);
        }else{
          navigate('/qnscomplete');
        }
        
      } catch (error) {
        console.error('Error:', error);
        navigate('/qnscomplete');
        
      }
    }else{
      try {
        const response = await axios.post(API_URL+"q", {
            currquestionid: currentQuestion.qid,
            answerid: currentQuestion.answers[0].aid,
            industry: userDetails.industry,
            employeeCount: userDetails.employeeCount
          }
        );
        if(response.data.qid){
          setOptionSelected(null);
          setSelectedDate(null);
          setComment("");
          setOptionalChange(false);
          setCurrentQuestion(response.data);
          getStoredOptionSelected(response.data);
        }else{
          localStorage.setItem('returnToReport', true);
          navigate('/qnscomplete');
        }
        
      } catch (error) {
        localStorage.setItem('returnToReport', true);
        navigate('/qnscomplete');
        console.error('Error:', error);
        
      }
    }
  };

  const cacheAnswer = async (ansopt) => {
    let answersToPost = localStorage.getItem('userAnswer');
    let ansObject = {
      qid: currentQuestion.qid,
      aid: currentQuestion.answers[0].aid,
      premiumq: currentQuestion.premiumq,
      answeroption: ansopt.optiontext,
      pl: currentQuestion.pl,
      category: currentQuestion.category,
      secimpact: currentQuestion.secimpact,
      assessment: (ansopt.assessment !== null) ? ansopt.assessment :"",
      recommendation: (ansopt.recommendation !== null) ? ansopt.recommendation : "",
      userComment: (comment !== null) ? comment: "",
      setReminder: (selectedDate !== null && selectedDate!=="")? "yes" : "no",
      commentReminderDateAsISO8601: selectedDate
    };
    if(answersToPost){
      answersToPost = JSON.parse(answersToPost);
      let flag = false;
      for (let i = 0; i < answersToPost.length; i++) {
        if(answersToPost[i].qid === ansObject.qid){
          answersToPost[i] = ansObject;
          flag = true;
        }
      }
      if(flag === false){
        answersToPost.push(ansObject);
      }
    }else{
      answersToPost = [ansObject];
    }
    let flag = false;
    for (let i = 0; i < storedAnswer.length; i++) {
        if(storedAnswer[i].qid === ansObject.qid){
          storedAnswer[i] = ansObject;
          flag = true;
        }
      }
      if(flag === false){
        storedAnswer.push(ansObject);
      }
    dispatch(storeAnswers(storedAnswer));
    localStorage.setItem('userAnswer', JSON.stringify(answersToPost));
  };

  const clickNextArrow = async () => {
    if(!optionalChange){
      setLoading(true);
      await getNextQuestion();
      setLoading(false);
    }else{
      if(optionSelected==="yes" || optionSelected==="no" || optionSelected==="i don't know" ){
        await yesNoButtonClick(optionSelected, true);
      }else{
        alert.error("Please choose relevant option!")
      }
    }
  };

  const clickPrevArrow = async () => {
    if(optionalChange){
      if(optionSelected==="yes" || optionSelected==="no" || optionSelected==="i don't know" ){
        await yesNoButtonClick(optionSelected, false);
      }else{
        alert.error("Please choose relevant option!");
        return;
      }
    }
    setLoading(true);
    await getFirstQuestion();
    setLoading(false);
  };

  const getFirstQuestion = async () => {
    try {
      let userDetails = getUserDetails(dispatch);
      const response = await axios.post(API_URL+"q", {
          industry: userDetails.industry,
          employeeCount: userDetails.employeeCount
        }
      );
      if(response.data.qid){
        setOptionSelected(null);
        setSelectedDate(null);
        setComment("");
        setOptionalChange(false);
        setCurrentQuestion(response.data);
        getStoredOptionSelected(response.data);
      }
      
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  return (
    <div>

      <section className="step-wrap pt-3 relative z-10 relative pb-10 md:pb-20">
      {loading ? (
        <Spinner style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) :(
        <div>
          <img alt="" className="floating-bg absolute w-auto left-1/2 -translate-x-1/2 bottom-0 hidden lg:block"
            src="images/gradient-bg.png"/>
          <img alt="" className="floating-bg absolute w-full left-1/2 -translate-x-1/2 bottom-0 lg:hidden opacity-50"
            src="images/gradient-bg-mobile.png"/>
          <div className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40 relative z-10">
            <div className="flex items-center justify-between flex-wrap">
              <div className="flex items-center flex-wrap w-full md:w-auto order-2 md:order-1 -mt-3 md:mt-0">
                <p className="text-[#767676] inline-block font-primary font-bold text-lg md:text-sm xl:text-lg w-full md:w-auto">
                  Step 02
                </p>
                <p className="text-sm xl:text-lg font-primary font-normal text-[#767676] text-left leading-normal ml-0 md:ml-2 xl:ml-8 w-full md:w-auto">
                {currentQuestion.pl} &gt;
                  <span className="text-white"> {currentQuestion.category} </span>
                </p>
              </div>
            </div>

            <div className="select-business-category bg-black rounded-xl border-stone-600 border-solid border relative mt-1">
              <div className="select-business-top p-6 lg:p-10">
                <div className="px-1 xl:px-20">
                  <h5 className="text-m xl:text-2xl font-primary font-normal text-white text-center leading-tight mb-1 mt-1">
                    {currentQuestion.qtext}
                  </h5>
                  <p className="text-center mt-1">
                    <a className="custom-link hand-cursor text-lg font-normal underline text-[#767676] transition ease-in-out duration-700" onClick={handleShow}>Why it matters?
                    </a>
                  </p>
                  
                  <p className="text-center mt-1 mb-2">
                    <a className="custom-link hand-cursor text-lg font-normal underline text-[#767676] transition ease-in-out duration-700" onClick={handleMitigationShow}>Mitigation Recommendation
                    </a>
                  </p>
                  
                  <div className="banner-steps flex relative w-full pt-1 pb-0 md:py-10 flex-wrap justify-start md:justify-center">
                    <div className="banner-steps-box banner-steps-box-2 text-center pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-12 xl:pr-12 w-1/3 sm:w-1/5 flex-none relative text-white hover:text-secondary mb-1 md:mb-0">
                      <div className="banner-icon-box relative w-full">
                        <span onClick={() => yesNoButtonClick("yes", true)} className={optionSelected === 'yes' ? "banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-secondary rounded-full mx-auto transition ease-in-out duration-700 rounded-full cursor-pointer" : "banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-primary rounded-full mx-auto transition ease-in-out duration-700 rounded-full cursor-pointer"}>
                          <img  alt=""
                            className="inline-block w-10 md:w-16"
                            src="images/check-yes.svg"/>
                        </span>
                      </div>
                      <p className="font-primary font-normal mt-2 mb-1 text-lg transition ease-in-out duration-700">
                        Yes
                      </p>
                    </div>
                    <div className="banner-steps-box banner-steps-box-3 text-center pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-12 xl:pr-12 w-1/3 sm:w-1/5 flex-none relative text-white hover:text-secondary mb-1 md:mb-0">
                      <div className="banner-icon-box relative w-full">
                        <span onClick={() => yesNoButtonClick(currentQuestion.answers[0].anserOptions[2].optiontext, true)} className={(optionSelected !== 'yes') && (optionSelected !== 'no') && (optionSelected !== null) ? "banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-secondary rounded-full mx-auto transition ease-in-out duration-700 rounded-full cursor-pointer" : "banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-primary rounded-full mx-auto transition ease-in-out duration-700 rounded-full cursor-pointer"}>
                          <img  alt=""
                            className="inline-block w-8 md:w-10"
                            src="images/question-mark.svg"/>
                        </span>
                      </div>
                      <p className="font-primary font-normal mt-2 mb-1 text-lg transition ease-in-out duration-700">
                        I don't know
                      </p>
                    </div>
                    <div className="banner-steps-box banner-steps-box-1 text-center pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-12 xl:pr-12 w-1/3 sm:w-1/5 flex-none relative text-white hover:text-secondary mb-1 md:mb-0">
                      <div className="banner-icon-box relative w-full">
                        <span onClick={() => yesNoButtonClick("no", true)}  className={optionSelected === 'no' ? "banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-secondary rounded-full mx-auto transition ease-in-out duration-700 rounded-full cursor-pointer" : "banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-primary rounded-full mx-auto transition ease-in-out duration-700 rounded-full cursor-pointer"}>
                          <img alt=""
                            className="inline-block w-6 md:w-8"
                            src="images/cross-no.svg"/>
                        </span>
                      </div>
                      <p className="font-primary font-normal mt-2 mb-1 text-lg transition ease-in-out duration-700">
                        No
                      </p>
                    </div>
                  </div>
                  <div className="optnl-notes-wrap flex items-center justify-center mb-6 md:mb-6 mt-3">
                    <p className="text-xs xl:text-xl font-primary font-normal text-[#767676] text-left leading-normal w-full md:w-auto">Optional Notes</p>
                    <div className="w-20 mx-2 flex"><span className="w-full h-px bg-[#767676]"></span></div>
                    <div className="optnl-plus-minus text-xl xl:text-3xl font-primary font-normal text-[#767676] text-left leading-normal md:w-auto cursor-pointer hover:text-white transition ease-in-out duration-700">
                      
                      {showOptional? (
                        <span className="optnl-minus-icon" onClick={() => setShowOptional(false)}>-</span>
                        ):
                        (<span className="optnl-plus-icon" onClick={() => setShowOptional(true)}>+</span>)
                      }
                    </div>
                    <div className="w-20 mx-2 flex"><span className="w-full h-px bg-[#767676]"></span></div>
                    <p className="text-xs xl:text-xl font-primary font-normal text-[#767676] text-left leading-normal w-full md:w-auto">Set Reminder</p>
                  </div>
                  
                  <div className="banner-steps flex relative w-full pt-2 pb-0 md:py-10 flex-wrap justify-start md:justify-center">
                    <div className="banner-steps-box text-center  pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-12 xl:pr-12 w-full sm:w-full flex-none relative text-white hover:text-secondary md:mb-0">
                      
                      <div className="banner-icon-box relative w-full">
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                      {showOptional? (
                      <textarea value={comment} onChange={handleCommentChange} rows="1" style={{
                          borderTop: 'none',
                          borderLeft: 'none',
                          borderRight: 'none',
                          fontSize: '15px',
                          borderBottom: '2px solid'
                        }}
                        placeholder="Enter Mitigation Comment (Optional)"
                        className="bg-transparent text-center outline-none border-b border-solid border-stone-500 text-white text-sm lg:text-xl font-primary font-normal w-full lg:w-3/5 md:w-3/5">
                      </textarea>
                      ): null}
                      </span>
                      </div>
                    </div>
                  </div>
                  
                  
                  <div className="banner-steps flex relative w-full mb-6 pt-4 pb-0 md:py-10 flex-wrap">
                    <div className="banner-steps-box banner-steps-box-1 text-right pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-4 xl:pr-4 w-full text-center sm:w-1/2 flex-none relative text-white hover:text-secondary md:mb-0">
                      <div className="banner-icon-box relative w-full">
                      {showOptional? (
                      <p className="sm:text-right text-[#767676] font-primary font-normal mt-2 mb-1 text-md transition ease-in-out duration-700">
                        Mitigation Target Date :
                      </p>
                      ): null}
                      </div>
                    </div>
                    <div className="banner-steps-box banner-steps-box-2 text-center md:text-left lg:text-left xl:text-left  pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-4 xl:pr-4 w-full sm:w-1/2 flex-none relative md:mb-0">
                      <div className="banner-icon-box relative w-full sm:text-left">
                      {showOptional? (
                      <DatePicker style={{
                          borderTop: 'none',
                          borderLeft: 'none',
                          borderRight: 'none',
                          fontSize: '15px',
                          borderBottom: '2px solid'
                        }} className="bg-transparent text-white text-sm md:text-lg font-primary rounded-sm border-slate-400 transition ease-in-out duration-700 md:mb-0" onChange={handleDateChange} value={selectedDate} format='dd/MM/yyyy'/>
                        ): null}
                      </div>
                    </div>
                  </div>
                  
                  {/* <div className="banner-steps flex relative w-full pt-4 mb-7 md:mb-10 sm:mb-5 pb-0 md:py-10 flex-wrap justify-center md:justify-center">
                    <div className="banner-steps-box banner-steps-box text-center pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-12 xl:pr-12 w-full sm:w-full flex-none relative text-white hover:custom-bg-secondary md:mb-0">
                      <div className="banner-icon-box relative w-full">
                        <button style={{width: '150px', height: '60px'}} className="text-black custom-btn-primary text-md sm:text-md items-center justify-center py-3 px-8 transition ease-in-out duration-700 rounded-full" onClick={() => buttonClick()}>
                        SUBMIT
                        </button>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip">Restart Assessment</Tooltip>}>
              <span onClick={clickPrevArrow} className="slider-arrow-left absolute left-4 bottom-4 md:left-10 md:bottom-10 cursor-pointer hover:invert transition ease-in-out duration-700">
                <img alt="" className="" src="images/slider-arrow-prev.svg" />
              </span>
              </OverlayTrigger>
              <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip">Next Question</Tooltip>}>
              <span onClick={clickNextArrow} className="slider-arrow-right absolute right-4 bottom-4 md:right-10 md:bottom-10 cursor-pointer hover:invert transition ease-in-out duration-700">
                <img alt="" className="" src="images/slider-arrow-next.svg" />
              </span>
              </OverlayTrigger>
            </div>

          </div>
        </div>
      )}

      </section>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="right-to-center-modal background-image-unset"
      >
        <Modal.Header closeButton>
          <Modal.Title>Why It Matters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p className="text-m xl:text-m text-center font-primary font-normal text-black leading-tight mb-1 mt-1">

          {currentQuestion.bihtext}
          </p>
        </Modal.Body>
      </Modal>
      <Modal
        show={mitigationShow}
        onHide={handleMitigationClose}
        backdrop="static"
        keyboard={false}
        className="right-to-center-modal background-image-unset"
      >
        <Modal.Header closeButton>
          <Modal.Title>Mitigation Recommendation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-m text-left xl:text-m font-primary font-normal text-black leading-tight mb-1 mt-1">
            {currentQuestion && currentQuestion.answers && currentQuestion.answers[0] && currentQuestion.answers[0].anserOptions && currentQuestion.answers[0].anserOptions[1] && currentQuestion.answers[0].anserOptions[1].recommendation ? currentQuestion.answers[0].anserOptions[1].recommendation.split(/\d+\./).filter(str => str!==undefined && str.toLowerCase()!=="" && str.toLowerCase()!==" ").map((str, index) => <li key={index}>{str}</li>) : "There is no mitigation recommendation based on your answer."}
          </p>
          <p className="text-center mt-1">
            <a className="custom-text-primary hand-cursor text-lg underline transition ease-in-out duration-700 hover:text-secondary" onClick={() => openAdditionalInfo()}>Additional Information
            </a>
          </p>
        </Modal.Body>
      </Modal>
    </div>
    
  );
}
export default StepQns;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../utility/constants';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../../utility/utils';

function NewReport() {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState({});
  const [report, setReport] = useState({"reportData":[]});
  const [dt, setDt] = useState(null);
  useEffect(() => {
    document.title = "Report | Firminy Solutions, LLC";
    const userDtl = getUserDetails(dispatch);
    if(userDtl.authToken === undefined){
      navigate('/home');
      alert.info("Your Session is Expired. Please Log In Again.");
    }
    setUserDetails(userDtl);
    getReport(userDtl);
    getTime();
  },[]);
  const getTime = async () => {
    const now = new Date();
    const month = now.toLocaleString('en-US', { month: 'short' });
    const day = now.getDate();
    const year = now.getFullYear();
    const hour = now.getHours();
    const minute = now.getMinutes().toString().padStart(2, '0'); // Ensure two-digit minute format
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12; // Convert hour to 12-hour format
    const formattedDate = `${month} ${day} - ${year} | ${formattedHour}.${minute} ${ampm} EST`;
    setDt(formattedDate);
  };
  const getReport = async (userDtl) => {
    let authToken = userDtl.authToken;
    if(authToken !== undefined){
      try {
        const response = await axios.post(API_URL+"report", {
            userid: userDtl.userid,
            industry: userDtl.industry,
            customerid: userDtl.customerid
        },
        {
          headers: {
            "Auth-Token": authToken
          }
        });
        if(response.data && response.data[0]!==-111){
            setReport(response.data);
        }else if(response.data && response.data[0]===-111){
            alert.info("You have not taken assessment yet.");
            setReport({"reportData":[]})
        }else{
            alert.error("Some error occurred");
        }
        
      } catch (error) {
        setReport({"reportData":[]})
        console.error('Error:', error);
        alert.error("Some error occurred");
      }
    }
  };
  const openSubscription = () => {
    localStorage.removeItem('userAnswer');
    navigate('/subscription');
  };
  const openAssessment = () => {
    localStorage.removeItem('userAnswer');
    navigate('/assessment');
  };

  return (
    <div>
      <section className="step-wrap pt-5 relative z-10 relative pb-10 md:pb-20">
        <img alt="gradient-bg"
          className="floating-bg absolute w-auto left-1/2 -translate-x-1/2 bottom-0 hidden lg:block"
          src="images/gradient-bg.png"
        />
        <img alt="gradient-bg-mobile"
          className="floating-bg absolute w-full left-1/2 -translate-x-1/2 bottom-0 lg:hidden opacity-50"
          src="images/gradient-bg-mobile.png"
        />
        <div
          className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40 relative z-10"
        >
          <div className="select-business-category bg-white">
            <div className="w-full flex items-center p-4 md:p-10">
              <div className="flex-auto pr-2 lg:pr-6 w-8/12 lg:w-9/12">
                <h4
                  className="text-xs md:text-xl lg:text-3xl leading-tight font-primary text-black font-normal"
                >
                  Cybersecurity Assessment Report
                </h4>
                <h5
                  className="text-xs md:text-lg lg:text-2xl leading-tight font-primary text-black font-bold my-1 lg:my-3"
                >
                  {userDetails.businessname}
                </h5>
                <p
                  className="text-xs md:text-sm lg:text-lg leading-tight font-primary text-black font-normal"
                >
                {userDetails.city}, {userDetails.state}, {userDetails.country}
                </p>
              </div>
              <div
                className="flex-auto pl-2 lg:pl-6 border-l-2 border-slate-300 border-solid w-4/12 lg:w-3/12"
              >
                <p
                  className="text-xs md:text-sm lg:text-lg leading-tight font-primary text-black font-normal text-left"
                >
                  {dt}
                  <strong className="block"> {userDetails.name} </strong>
                  {userDetails.designation}
                </p>
              </div>
            </div>
            {report.reportData.map((item, index) => (
                <div key={index}>
                    <div className="w-full p-4 md:p-10 bg-black">
                    <h2 className="text-lg md:text-xl lg:text-4xl leading-tight font-primary custom-text-primary font-normal">{item.pl}</h2>
                    </div>
                    <div className="w-full flex flex-wrap">
                    <div
                        className="w-1/2 md:w-1/4 flex-auto flex justify-center items-center py-6 px-2 lg:py-12 lg:px-6 bg-[#4e4d4d]"
                    >
                        <div className="text-center">
                        <p
                            className="text-xs md:text-lg xl:text-xl md:leading-5 xl:leading-6 text-white font-primary font-light mb-3"
                        >
                            Cybersecurity Requirements Answered
                        </p>
                        <h4
                            className="text-lg md:text-3xl xl:text-5xl text-white font-primary font-normal"
                        >
                            {item.cybersecurity_req_answered_percentage}%
                        </h4>
                        </div>
                    </div>
                    <div
                        className="w-1/2 md:w-1/4 flex-auto flex justify-center items-center py-6 px-2 lg:py-12 lg:px-6 bg-[#292828]"
                    >
                        <div className="text-center">
                        <p
                            className="text-xs md:text-lg xl:text-xl md:leading-5 xl:leading-6 text-white font-primary font-light mb-3"
                        >
                            Requirements Not Met or Unanswered
                        </p>
                        <h4
                            className="text-lg md:text-3xl xl:text-5xl text-white font-primary font-normal"
                        >
                            {item.req_unmet_unanswered_percentage}%
                        </h4>
                        </div>
                    </div>
                    <div
                        className="w-1/2 md:w-1/4 flex-auto flex justify-center items-center py-6 px-2 lg:py-12 lg:px-6 bg-[#292828] md:bg-[#4e4d4d]"
                    >
                        <div className="text-center">
                        <p
                            className="text-xs md:text-lg xl:text-xl md:leading-5 xl:leading-6 text-white font-primary font-light mb-3"
                        >
                            High Severity Requirements Not Met or Unanswered
                        </p>
                        <h4
                            className="text-lg md:text-3xl xl:text-5xl text-white font-primary font-normal"
                        >
                            {item.high_sev_req_unmet_unanswered}
                        </h4>
                        </div>
                    </div>
                    <div
                        className="w-1/2 md:w-1/4 flex-auto flex justify-center items-center py-6 px-2 lg:py-12 lg:px-6 bg-[#4e4d4d] md:bg-[#292828]"
                    >
                        <div className="text-center">
                        <p
                            className="text-xs md:text-lg xl:text-xl md:leading-5 xl:leading-6 text-white font-primary font-light mb-3"
                        >
                            Cybersecurity or Ransomware Exposure
                        </p>
                        <h4
                            className="text-lg md:text-3xl xl:text-5xl text-white font-primary font-normal"
                        >
                            {item.cybsec_or_ransomware_exposure}
                        </h4>
                        </div>
                    </div>
                    </div>
                    {userDetails.ispremium==="yes" ? (
                      <div>
                      {item.details.map((subitem, subindex) => (
                      <div key={subindex} className={subindex%2===0 ? "w-full px-4 md:px-10 py-4 md:py-16 bg-[#efeaea]" : "w-full px-4 md:px-10 py-4 md:py-16 bg-[#dcdcdc]"}>
                      {/* <h2 className="text-lg md:text-xl lg:text-4xl md:leading-tight font-primary text-black font-normal mb-3">Protection Level 01</h2> */}
                      <h3 className="text-lg md:text-xl lg:text-4xl md:leading-tight font-primary text-black font-bold mb-6">{subitem.category}</h3>
                      <div className="w-full flex flex-wrap">
                          <div
                          className="w-1/2 md:w-1/4 flex-auto flex justify-center items-center py-6 px-2 lg:py-12 lg:px-6 md:bg-black bg-[#292828]"
                          >
                          <div className="text-center">
                              <div className="flex justify-center mb-4 md:mb-5">
                              <img className="w-12 md:w-full" src="images/frmy-check.svg" alt="check"/>
                              </div>
                              <p
                              className="text-xs md:text-lg xl:text-xl md:leading-5 xl:leading-6 text-white font-primary font-light mb-3"
                              >
                              Protected
                              </p>
                              <h4
                              className="text-lg md:text-3xl xl:text-5xl text-white font-primary font-normal"
                              >
                              {subitem.protected}
                              </h4>
                          </div>
                          </div>
                          <div
                          className="w-1/2 md:w-1/4 flex-auto flex justify-center items-center py-6 px-2 lg:py-12 lg:px-6 bg-[#4e4d4d]"
                          >
                          <div className="text-center">
                              <div className="flex justify-center mb-4 md:mb-5">
                              <img className="w-12 md:w-3/4" src="images/frmy-warning.svg" alt="warning"/>
                              </div>
                              <p
                              className="text-xs md:text-lg xl:text-xl md:leading-5 xl:leading-6 text-white font-primary font-light mb-3"
                              >
                              Not Protected
                              </p>
                              <h4
                              className="text-lg md:text-3xl xl:text-5xl text-white font-primary font-normal"
                              >

                              {subitem.notprotected}
                              </h4>
                          </div>
                          </div>
                          <div
                          className="w-full md:w-1/2 flex-auto flex justify-center items-center py-6 px-2 lg:py-12 lg:px-6 bg-black"
                          >
                          <div className="text-center">
                              <div className="flex justify-center">
                              {/* <img src="images/frmy-star.svg" alt="star"/> */}
                              </div>
                              <div className="button-box flex justify-center mt-6 md:mt-8">
                              <button
                                  onClick={() => openAssessment()}
                                  className="button-primary text-black custom-bg-primary text-sm md:text-xl font-bold flex items-center justify-center py-3 px-8 transition ease-in-out duration-700 rounded-full hover:bg-secondary"
                              >
                                  Review Open Items
                              </button>
                              </div>
                              <p
                              className="text-xs md:text-lg xl:text-xl md:leading-5 xl:leading-6 text-white font-primary font-light mt-5"
                              >
                                  Review Requirements Needing Attention                              
                              </p>
                          </div>
                          </div>
                      </div>
                      </div>
                      ))}
                    </div>
                    ): (
                      <div className="select-business-bottom py-8 px-4 md:py-10 md:px-10 bg-black">
                        <div className="banner-steps flex relative w-full py-0 md:py-10 items-center flex-wrap">
                          <div className="relative w-full lg:w-2/3 flex-auto">
                            <h6 className="text-2xl font-primary font-semibold custom-text-primary text-left lg:text-left leading-tight mb-2">
                            Cybersecurity Awareness and Assessment Service:
                            </h6>
                              <ul>
                                <li className="bullet-li text-xl font-primary font-normal text-white text-left lg:text-left leading-tight mb-6 lg:mb-0">
                                a detailed assessment of your cybersecurity posture

                                </li>
                                <li className="bullet-li text-xl font-primary font-normal text-white text-left lg:text-left leading-tight mb-6 lg:mb-0">
                                training your employees to detect phishing and ransomware threats                  

                                </li>
                                <li className="bullet-li text-xl font-primary font-normal text-white text-left lg:text-left leading-tight mb-6 lg:mb-0">
                                prepare you with an incident response plan in case of ransomware attack                  

                                </li>

                              </ul>
                          </div>
                          <div className="flex relative w-full lg:w-1/3 flex-auto">
                            <div className="w-full text-center">
                              <div className="button-box flex justify-center">
                                <button onClick={() => openSubscription()} className="button-primary text-black custom-btn-secondary text-sm md:text-xl flex items-center justify-center font-bold py-4 px-8 transition ease-in-out duration-700 rounded-full hover:bg-secondary">
                                  <img alt="lock" className="inline-block mr-4" src="images/lock.svg" />
                                  Protect Your Business Now
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
            </div>
            ))}
            <div className="w-full flex items-center p-4 md:p-10">
              <div
                className="flex-auto w-4/12 lg:w-7/12"
              >
                <p
                  className="text-xs md:text-lg lg:text-2xl leading-tight font-primary text-black font-normal text-left"
                >
                  Confidential
                </p>
              </div>
              <div className="flex-auto lg:pr-6 w-8/12 lg:w-5/12">
                <h4
                  className="text-xs md:text-sm lg:text-base leading-tight font-primary text-black font-normal"
                >
                  Report prepared by
                </h4>
                <h5
                  className="text-xs md:text-lg lg:text-2xl leading-tight font-primary text-black md:font-normal font-semibold my-1 lg:my-1"
                >
                  Firminy Solutions LLC
                </h5>
                <p
                  className="text-xs md:text-sm lg:text-sm leading-tight font-primary text-black font-normal"
                >
                  Protecting Businesses Against Cybersecurity Threats
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default NewReport;
import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      <div className="container mx-auto px-4 sm:px-10 lg:px-20 xl:px-30 2xl:px-40">
        <div className="footer-box flex items-center justify-between border-t border-slate-700 border-solid py-6 flex-wrap">
          <div className="footer-left flex-auto w-full sm:w-1/2">
            <p className="font-regular font-primary text-gray-500 text-xs text-left md:text-left">
              All copyrights to Firminy Solutions, LLC, USA
            </p>
          </div>
          <div className="footer-right flex-auto w-full sm:w-1/2 mt-1 sm:mt-0">
            <ul className="footer-menu flex items-center justify-center md:justify-end">
              <li className="leading-none">
                <a className="font-regular font-primary text-gray-500 text-xs hover:text-white transition ease-in-out duration-700 leading-none">
                  Privacy Policy
                </a>
              </li>
              <li className="ml-1 pl-1 md:ml-4 md:pl-4 border-l border-slate-700 border-solid leading-none">
                <a className="font-regular font-primary text-gray-500 text-xs hover:text-white transition ease-in-out duration-700 leading-none">
                  Terms of Use
                </a>
              </li>
              <li className="ml-1 pl-1 md:ml-4 md:pl-4 border-l border-slate-700 border-solid leading-none">
                <a  className="font-regular font-primary text-gray-500 text-xs hover:text-white transition ease-in-out duration-700 leading-none">
                  Cookie Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
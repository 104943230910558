import React, {useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../utility/utils';

function ChooseBusiness() {
  document.title = "Business | Firminy Solutions, LLC";
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countEmp, setCountEmp] = useState(null);
  const [industry, setIndustry] = useState(null);
  const userDetails = getUserDetails(dispatch);
  useEffect(() => {
    const clearLocalStorage = async () => {
      localStorage.removeItem('userAnswer');
      localStorage.removeItem('returnToReport');
    };
    const directToAssessment = async () => {
      if(userDetails.authToken !== undefined){
        if(userDetails.industry!==undefined && userDetails.employeecount!==undefined){
          navigate('/assessment');
        }else{
          navigate('/choosebusiness');
        }
      }
    };
    clearLocalStorage();
    directToAssessment();
  }, []);

  const countButtonClick = async (value) => {
    if(industry===null || industry===""){
      alert.error("Please choose industry");
      return;
    }else{
      setCountEmp(value);  
      userDetails["industry"] = industry;
      userDetails["employeecount"] = value; 
      localStorage.setItem('userDetails', JSON.stringify(userDetails));   
      navigate('/assessment');
    }

  };
  
  const industryButtonClick = (category) => {
    setIndustry(category);
  };


  return (
    <div>

      <section className="step-wrap pt-3 relative z-10 relative pb-8 md:pb-20">
        <img alt="" className="floating-bg absolute w-auto left-1/2 -translate-x-1/2 bottom-0 hidden lg:block" src="images/gradient-bg.png"/>
        <img alt="" className="floating-bg absolute w-full left-1/2 -translate-x-1/2 bottom-0 lg:hidden"
          src="images/gradient-bg-mobile2.png"/>
        <div className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40 relative z-10">
          <p className="text-[#767676] inline-block font-primary font-bold text-lg">
            Step 01
          </p>
          <h4 className="text-md md:text-md font-primary font-normal text-[#767676] text-left leading-tight mb-1">
            Tell us a bit about your business.
          </h4>
          <div className="select-business-category bg-black rounded-xl border-stone-600 border-solid border">
            <div className="select-business-top p-4 md:p-6 lg:p-10 border-stone-600 border-solid border-b">
              <h5 className="text-md md:text-md font-primary font-normal text-white text-center leading-tight mb-1 mt-3 md:mt-0">
                Select Your Business Category <sup className="text-red-500">*</sup>
              </h5>
              <div className="banner-steps flex relative w-full pt-5 pb-0 md:py-10 flex-wrap justify-start md:justify-center">
                <div className="banner-steps-box banner-steps-box-1 text-center pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-12 xl:pr-12 w-1/3 sm:w-1/5 flex-none relative text-white hover:text-secondary mb-3 md:mb-0">
                  <div className="banner-icon-box relative w-full">
                    <button onClick={() => industryButtonClick("Healthcare")} className={industry === 'Healthcare' ? "banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-secondary rounded-full mx-auto transition ease-in-out duration-700 rounded-full hover:custom-bg-primary" :"banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-primary rounded-full mx-auto transition ease-in-out duration-700 rounded-full hover:custom-bg-secondary"}>
                      <img alt="" 
                        className="inline-block w-10 md:w-16"
                        src="images/sb-healthcare.svg"/>
                    </button>
                  </div>
                  <p className="font-primary font-normal mt-5 mb-1 text-xs md:text-lg transition ease-in-out duration-700">
                    Healthcare
                  </p>
                </div>
                <div className="banner-steps-box banner-steps-box-5 text-center pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-12 xl:pr-12 w-1/3 sm:w-1/5 flex-none relative text-white hover:text-secondary mb-3 md:mb-0">
                  <div className="banner-icon-box relative w-full">
                    <button onClick={() => industryButtonClick("Financial Services")} className={industry === 'Financial Services' ? "banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-secondary rounded-full mx-auto transition ease-in-out duration-700 rounded-full hover:custom-bg-primary" :"banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-primary rounded-full mx-auto transition ease-in-out duration-700 rounded-full hover:custom-bg-secondary"}>
                      <img alt="" 
                        className="inline-block w-10 md:w-16"
                        src="images/sb-financial.svg"/>
                    </button>
                  </div>
                  <p className="font-primary font-normal mt-5 mb-1 text-xs md:text-lg transition ease-in-out duration-700">
                  Financial Services
                  </p>
                </div>
                <div className="banner-steps-box banner-steps-box-3 text-center pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-12 xl:pr-12 w-1/3 sm:w-1/5 flex-none relative text-white hover:text-secondary mb-3 md:mb-0">
                  <div className="banner-icon-box relative w-full">
                    <button onClick={() => industryButtonClick("Legal")} className={industry === 'Legal' ? "banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-secondary rounded-full mx-auto transition ease-in-out duration-700 rounded-full hover:custom-bg-primary" :"banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-primary rounded-full mx-auto transition ease-in-out duration-700 rounded-full hover:custom-bg-secondary"}>
                      <img alt="" 
                        className="inline-block w-10 md:w-16"
                        src="images/sb-legal.svg"/>
                    </button>
                  </div>
                  <p className="font-primary font-normal mt-5 mb-1 text-xs md:text-lg transition ease-in-out duration-700">
                  Legal
                  </p>
                </div>
                <div className="banner-steps-box banner-steps-box-2 text-center pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-12 xl:pr-12 w-1/3 sm:w-1/5 flex-none relative text-white hover:text-secondary mb-3 md:mb-0">
                  <div className="banner-icon-box relative w-full">
                    <button onClick={() => industryButtonClick("Retail")}  className={industry === 'Retail' ? "banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-secondary rounded-full mx-auto transition ease-in-out duration-700 rounded-full hover:custom-bg-primary" :"banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-primary rounded-full mx-auto transition ease-in-out duration-700 rounded-full hover:custom-bg-secondary"}>
                      <img alt="" 
                        className="inline-block w-10 md:w-16"
                        src="images/sb-retail.svg"/>
                    </button>
                  </div>
                  <p className="font-primary font-normal mt-5 mb-1 text-xs md:text-lg transition ease-in-out duration-700">
                    Retail
                  </p>
                </div>
                <div className="banner-steps-box banner-steps-box-4 text-center pl-2 pr-2 md:pl-1 md:pr-1 lg:pl-4 lg:pr-4 xl:pl-12 xl:pr-12 w-1/3 sm:w-1/5 flex-none relative text-white hover:text-secondary mb-3 md:mb-0">
                  <div className="banner-icon-box relative w-full">
                    <button onClick={() => industryButtonClick("Other")} className={industry === 'Other' ? "banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-secondary rounded-full mx-auto transition ease-in-out duration-700 rounded-full hover:custom-bg-primary" :"banner-icon flex items-center justify-center w-16 h-16 md:w-28 md:h-28 custom-btn-primary rounded-full mx-auto transition ease-in-out duration-700 rounded-full hover:custom-bg-secondary"}>
                      <img alt="" 
                        className="inline-block w-10 md:w-16"
                        src="images/sb-services.svg"/>
                    </button>
                  </div>
                  <p className="font-primary font-normal mt-5 mb-1 text-xs md:text-lg transition ease-in-out duration-700">
                  Other
                  </p>
                </div>
              </div>
            </div>
            <div className="select-business-bottom p-4 md:p-6 lg:p-10">
              <h5 className="text-md md:text-md font-primary font-normal text-white text-center leading-tight mb-2 mt-4 md:mt-0">
                How many employees do you have?
                <sup className="text-red-500">*</sup>
              </h5>
              <div className="employee-count flex w-full items-center justify-center relative py-10 pt-5 pb-0 md:py-10 flex-wrap justify-start md:justify-center">
                <button style={countEmp === '<25' ? {backgroundColor: '2px solid red', padding: '20px'}: {backgroundColor: '2px solid blue', padding: '20px'}} onClick={() => countButtonClick("<10")} className="employee-count-text text-black custom-btn-white border-0 text-base md:text-2xl font-primary font-normal leading-tight h-8 md:h-14 flex items-center justify-center px-4 md:px-6 mx-1 md:mx-3 min-w-16 mb-2 md:min-w-32 rounded-full transition ease-in-out duration-700">
                 &lt; 25
                </button>
                <button onClick={() => countButtonClick("<100")} className="employee-count-text text-black custom-btn-white border-0 text-base md:text-2xl font-primary font-normal leading-tight h-8 md:h-14 flex items-center justify-center px-4 md:px-6 mx-1 md:mx-3 min-w-16 mb-2 md:min-w-32 rounded-full transition ease-in-out duration-700">
                  25-100
                </button>
                <button onClick={() => countButtonClick("<200")} className="employee-count-text text-black custom-btn-white border-0 text-base md:text-2xl font-primary font-normal leading-tight h-8 md:h-14 flex items-center justify-center px-4 md:px-6 mx-1 md:mx-3 min-w-16 mb-2 md:min-w-32 rounded-full transition ease-in-out duration-700">
                  100-200
                </button>
                <button onClick={() => countButtonClick("Other")} className="employee-count-text text-black custom-btn-white border-0 text-base md:text-2xl font-primary font-normal leading-tight h-8 md:h-14 flex items-center justify-center px-4 md:px-6 mx-1 md:mx-3 min-w-16 mb-2 md:min-w-32 rounded-full transition ease-in-out duration-700">
                  200+
                </button>
                {/* <button onClick={() => countButtonClick("100-500")} className="employee-count-text text-black custom-btn-white border-0 text-base md:text-2xl font-primary font-normal leading-tight h-8 md:h-14 flex items-center justify-center px-4 md:px-6 mx-1 md:mx-3 min-w-16 mb-2 md:min-w-32 rounded-full transition ease-in-out duration-700">
                  100-500
                </button>
                <button onClick={() => countButtonClick("500-1000")} className="employee-count-text text-black custom-btn-white border-0 text-base md:text-2xl font-primary font-normal leading-tight h-8 md:h-14 flex items-center justify-center px-4 md:px-6 mx-1 md:mx-3 min-w-16 mb-2 md:min-w-32 rounded-full transition ease-in-out duration-700">
                  500-1000
                </button>
                <button onClick={() => countButtonClick("1000+")} className="employee-count-text text-black custom-btn-white border-0 text-base md:text-2xl font-primary font-normal leading-tight h-8 md:h-14 flex items-center justify-center px-4 md:px-6 mx-1 md:mx-3 min-w-16 mb-2 md:min-w-32 rounded-full transition ease-in-out duration-700">
                  1000+
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default ChooseBusiness;
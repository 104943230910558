import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './layout/header';
import Footer from './layout/footer';
import Home from './pages/home';
import ChooseBusiness from './pages/choose_business'
import Assessment from './pages/assessment';
import StepQns from './pages/qns/stepqns';
import QnsComplete from './pages/reports/qns_complete';
import './App.css';
import UserProfile from './pages/profile/user_profile';
import CallbackPage from './pages/profile/callback';
import NewReport from './pages/reports/newreport';
import Subscription from './pages/subscription/subscription';
import SubscriptionDtl from './pages/subscription/subscriptiondtl';
import InviteUser from './pages/subscription/inviteuser';
function App() {
  return (
    <Router>
          <main>
        <Header />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/callback" element={<CallbackPage/>} />
          <Route path="/userprofile" element={<UserProfile/>} />
          <Route path="/choosebusiness" element={<ChooseBusiness/>} />
          <Route path="/assessment" element={<Assessment/>} />
          <Route path="/stepqns" element={<StepQns/>} />
          <Route path="/qnscomplete" element={<QnsComplete/>} />
          <Route path="/report" element={<NewReport/>} />
          <Route path="/subscription" element={<Subscription/>} />
          <Route path="/subscriptiondtl" element={<SubscriptionDtl/>} />
          <Route path="/inviteuser" element={<InviteUser/>} />
        </Routes>
        <Footer/>
        </main>
    </Router>
  );
}

export default App;

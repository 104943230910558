import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../utility/utils';

function Home() {
  const videoId = '2I7Jn1qHw4U';
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const userDetails = getUserDetails(dispatch);
  useEffect(() => {
    document.title = "Home | Firminy Solutions, LLC";
    localStorage.removeItem('userAnswer');
    localStorage.removeItem('returnToReport');
  }, []);

  const startAssessment = () => {
    if(userDetails.authToken === undefined){
      navigate('/choosebusiness');
    }else{
      if(userDetails.industry!==undefined && userDetails.employeecount!==undefined){
        navigate('/assessment');
      }else{
        navigate('/choosebusiness');
      }
    }
  };

  return (
    <div>

    <section className="banner pt-6 md:pt-10 lg:pt-20 relative z-10">
            <div className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40">
              <h1 className="text-4xl lg:text-5xl font-primary font-medium text-white text-center leading-tight">
                Get a <span className="custom-text-primary"> Confidential </span> <br />
                Cybersecurity Assessment <br />
                for your business
              </h1>
              <div className="banner-steps flex flex-wrap relative w-full py-8">
                <div className="banner-steps-box banner-steps-box-1 text-center lg:pl-8 lg:pr-8 xl:pl-12 xl:pr-12 w-full md:w-1/3 flex-auto relative pb-32 md:pb-0">
                  <img alt="" className="banner-arrow-top absolute block md:hidden bottom-6 left-1/2 -translate-x-1/2" src="images/banner-arrow-top.svg"/>
                  <div className="banner-icon-box relative w-full">
                    <span className="banner-icon hand-cursor flex items-center justify-center w-28 h-28 custom-btn-primary rounded-full mx-auto transition ease-in-out duration-700 rounded-full">
                      <img alt="" className="inline-block" src="images/banner-step-1.svg" />
                    </span>
                    <img alt="" className="banner-arrow-left absolute hidden md:block md:w-1/2 md:w-[78%] lg:w-[84%]" src="images/banner-arrow-left.svg"/>
                  </div>
    
                  <h5 className="text-white font-primary font-semibold mt-5 mb-1/2 text-lg">
                    Step 01
                  </h5>
                  <p className="text-gray-300 inline-block font-primary font-normal text-base md:text-xs xl:text-base">
                  Answer a few questions about your current cybersecurity posture.
                  </p>
                </div>
                <div className="banner-steps-box banner-steps-box-2 text-center lg:pl-8 lg:pr-8 xl:pl-12 xl:pr-12 w-full md:w-1/3 flex-auto relative pb-32 md:pb-0">
                  <img alt="" className="banner-arrow-bottom absolute block md:hidden bottom-6 left-1/2 -translate-x-1/2" src="images/banner-arrow-bottom.svg"/>
                  <div className="banner-icon-box relative w-full">
                    <span className="banner-icon hand-cursor flex items-center justify-center w-28 h-28 custom-btn-primary rounded-full mx-auto transition ease-in-out duration-700 rounded-full">
                      <img alt="" className="inline-block" src="images/banner-step-2.svg" />
                    </span>
                    <img alt="" className="banner-arrow-right absolute hidden md:block md:w-1/2 md:w-[78%] lg:w-[84%]" src="images/banner-arrow-right.svg"/>
                  </div>
    
                  <h5 className="text-white font-primary font-semibold mt-5 mb-1 text-lg">
                    Step 02
                  </h5>
                  <p className="text-gray-300 inline-block font-primary font-normal text-base md:text-xs xl:text-base">
                  Track and drive mitigation of potential cybersecurity gaps using our interactive report and consulting services.
                  </p>
                </div>
                <div className="banner-steps-box banner-steps-box-3 text-center lg:pl-8 lg:pr-8 xl:pl-12 xl:pr-12 w-full md:w-1/3 flex-auto">
                  <div className="banner-icon-box relative w-full">
                    <span className="banner-icon hand-cursor flex items-center justify-center w-28 h-28 custom-btn-primary rounded-full mx-auto transition ease-in-out duration-700 rounded-full">
                      <img alt="" className="inline-block" src="images/banner-step-3.svg" />
                    </span>
                  </div>
                  <h5 className="text-white font-primary font-semibold mt-5 mb-1 text-lg">
                    Step 03
                  </h5>
                  <p className="text-gray-300 inline-block font-primary font-normal text-base md:text-xs xl:text-base">
                  Maintain a strong cybersecurity posture with ongoing awareness training for employees, updated security assessments and an incident response plan.
                  </p>
                </div>
              </div>
              <div className="banner-bottom w-full mt-1 md:mt-1 lg:mt-2">
                <div className="button-box flex justify-center">
                  <button onClick={startAssessment} className="text-black custom-btn-secondary text-xl sm:text-2xl flex items-center justify-center py-4 px-8 transition ease-in-out duration-700 rounded-full">
                    Start Assessment
                    <img alt="arrow-right" className="inline-block ml-4" src="images/button-arrow-right.svg"/>
                  </button>
                </div>
              </div>
            </div>
    </section>
    <section className="video-wrapper pt-2 md:pt-10 lg:pt-20 relative">

            <img alt="" className="floating-bg absolute w-auto left-1/2 -translate-x-1/2 bottom-0 hidden lg:block" src="images/gradient-bg.png"/>
            <img alt="" className="floating-bg absolute w-full left-1/2 -translate-x-1/2 bottom-0 lg:hidden opacity-50" src="images/gradient-bg-mobile.png"/>
            <div className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40">
              <div className="video-wrap px-5 sm:px-16 lg:px-24">
                <div className="video-container bg-video-back-image cursor-pointer px-4 py-7 sm:px-10 sm:py-36 md:px-10 md:py-28 lg:px-10 lg:py-24 xl:px-10 xl:py-36 bg-cover bg-no-repeat bg-center relative rounded-tl-3xl rounded-tr-3xl">

                  <h2 className="font-primary font-thin text-2xl md:text-5xl lg:text-7xl text-center text-[#767676] leading-snug">
                    How Ransomware is destroying <br />
                    small businesses...
                  </h2>
                    <img alt="icon-play" onClick={handleShow} className="block absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hover:rotate-180 transition ease-in-out duration-700" src="images/icon-play.svg"/>
                </div>
              </div>
            </div>
    </section>
    <Modal  show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Protecting your  business from ransomeware</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <div style={{ width: '100%', position: 'relative', paddingBottom: '56.25%' }}>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            allowFullScreen
            style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, border: 'none' }}
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
    </div>
  );
}
export default Home;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { API_URL } from '../utility/constants';
import {storeAnswers, storeFirstQuestion} from '../services/actions';
import { useAlert } from 'react-alert';
import { Spinner } from 'react-bootstrap';
import { getUserDetails } from '../utility/utils';
import Modal from 'react-bootstrap/Modal';

function Assessment() {
  document.title = "Assessment | Firminy Solutions, LLC";
  const alert = useAlert();
  const dispatch = useDispatch();
  const videoId = 'uHpGyiKLE1Q';
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showRestart, setShowRestart] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    localStorage.removeItem("userAnswer");
    localStorage.removeItem("returnToReport");
    const getStoredAnswers = async () => {
      const userDetails = getUserDetails(dispatch);
      if(userDetails.userid){
        try {
          const response = await axios.post(API_URL+"assessment", 
          {
            "userid": userDetails.userid,
            "customerid": userDetails.customerid,
            "assessmentFilter": "All"
          },
          {
            headers: {
              "Auth-Token": userDetails.authToken
            }
          });
          if(response.data && response.data[0]===-111){
            setShowRestart(false);
            dispatch(storeAnswers([]));
          }else if(response.data && response.data[0]!==-111){
            dispatch(storeAnswers(response.data));
            setShowRestart(true);
          }
        } catch (error) {
          console.error('Error:', error);
          setShowRestart(false);
          dispatch(storeAnswers([]));
        }
      }else{
        dispatch(storeAnswers([]));
        setShowRestart(false);
      }
    };
    getStoredAnswers();
    
  }, []);

  const getFirstQuestion = async () => {
    setLoading(true);
    const userDetails = getUserDetails(dispatch);
    const response = await axios.post(API_URL+"q", {
        industry: userDetails.industry,
        employeeCount: userDetails.employeeCount
      }
    );
    if(response.data.qid){
      dispatch(storeFirstQuestion(response.data));
      navigate('/stepqns');
    }
    setLoading(false);
  };
  const getCurrentQuestion = async () => {
    setLoading(true);
    try{
      const userDetails = getUserDetails(dispatch);
      const response = await axios.post(API_URL+"q", {
        customerid: userDetails.customerid,
        userid: userDetails.userid,
        industry: userDetails.industry,
        employeeCount: userDetails.employeeCount
      },
      {
        headers: {
          "Auth-Token": userDetails.authToken
        }
      }
      );
      if(response.data.qid){
        dispatch(storeFirstQuestion(response.data));
        navigate('/stepqns');
      }else{
        alert.error("You have completed all questions. Please restart assessment.");
      }
    } catch (error) {
      console.error('Error:', error);
      alert.error("You have completed all questions. Please restart assessment.");
    } finally{
      setLoading(false);
    }
  };


  return (
    <div>

      <section className="banner pt-5 md:pt-20 relative z-10">
      {loading ? (
        <Spinner style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} animation="border" role="output">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      ): (
        <div className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40">
          <div className="answer-few flex items-center flex-wrap">
            <div className="answer-few-left w-full lg:w-1/2 flex-none">
              <h1 className="custom-text-primary text-5xl custom-text-primary text-left lg:text-left font-bold">
              $1.85 million
              </h1>
              <p className="text-white font-primary text-xl font-regular mt-1 text-left lg:text-left mb-5 lg:mb-0">
              
              average cost of recovering from a ransomware incident in 2023.              </p>
            </div>
            <div className="answer-few-right w-full lg:w-1/2 flex-none">
              <div className="button-box flex justify-center mb-10 lg:mb-0">
              {showRestart ? ( 
                  <button style={{width: '300px'}} onClick={getCurrentQuestion} className="button-primary text-black custom-bg-primary text-md xl:text-lg items-center justify-center font-bold py-4 px-8 transition ease-in-out duration-700 rounded-full">
                    Continue Assessment
                    <img alt="button-arrow-right" className="inline-block ml-4" src="images/button-arrow-right.svg"/>
                  </button>
              ) : (
                null
              )}
              {showRestart === false ? (
                <button style={{width: '450px'}} onClick={getFirstQuestion} className="button-primary text-black custom-btn-secondary text-md xl:text-lg items-center justify-center font-bold py-4 px-8 transition ease-in-out duration-700 rounded-full">
                  Answer Few Assessment Questions
                  <img alt="button-arrow-right" className="inline-block ml-4" src="images/button-arrow-right.svg"/>
                </button>
              ):(
                null
              )}
              </div>
            </div>
          </div>
          {showRestart ? ( 
              <div className="answer-few flex items-center flex-wrap">
                <div className="answer-few-left w-full lg:w-1/2 flex-none">
                  <p className="text-white font-primary text-xl font-regular mt-1 text-left lg:text-left mb-5 lg:mb-0">
                    You have already taken assessment. Do you want to restart?
                  </p>
                </div>
                <div className="answer-few-right w-full lg:w-1/2 flex-none">
                  <div className="button-box flex justify-center mb-5 lg:mb-0">
                    <button style={{width: '300px'}} onClick={getFirstQuestion} className="button-primary text-black custom-bg-secondary text-md xl:text-lg items-center justify-center font-bold py-4 px-8 transition ease-in-out duration-700 rounded-full">
                      Restart Assessment
                      <img alt="button-arrow-right" className="inline-block ml-4" src="images/button-arrow-right.svg"/>
                    </button>
                  </div>
                </div>
              </div>
            ):null}
        </div>
      )}
      </section>
      <section className="video-wrapper pt-2 md:pt-10 lg:pt-20 relative">
        <img alt="gradient-bg" className="floating-bg absolute w-auto left-1/2 -translate-x-1/2 bottom-0 hidden lg:block" src="images/gradient-bg.png"/>
        <img alt="gradient-bg" className="floating-bg absolute w-full left-1/2 -translate-x-1/2 bottom-0 lg:hidden opacity-50" src="images/gradient-bg-mobile.png"/>
        <div className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40">
          <div className="video-wrap px-5 sm:px-16 lg:px-24">
            <div className="video-container bg-video-back-image cursor-pointer px-4 py-7 sm:px-10 sm:py-36 md:px-10 md:py-28 lg:px-10 lg:py-24 xl:px-10 xl:py-36 bg-cover bg-no-repeat bg-center relative rounded-tl-3xl rounded-tr-3xl">
              <h2 className="font-primary font-thin text-2xl md:text-5xl lg:text-7xl text-center text-[#767676] leading-snug">
                How Ransomware is destroying <br />
                small businesses...
              </h2>
              <img alt="icon-play" onClick={handleShow} className="block absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 hover:rotate-180 transition ease-in-out duration-700"
                src="images/icon-play.svg"/>
            </div>
          </div>
        </div>
      </section>  
      <Modal  show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Why Cybersecurity is important </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <div style={{ width: '100%', position: 'relative', paddingBottom: '56.25%' }}>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            allowFullScreen
            style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, border: 'none' }}
          ></iframe>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
        <button className="text-black custom-bg-secondary text-l sm:text-l flex items-center justify-center py-4 px-8 transition ease-in-out duration-700 rounded-full hover:custom-bg-primary" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer> */}
    </Modal>  
    </div>
  );
}
export default Assessment;


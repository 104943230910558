import { STORE_FIRST_QUESTION, STORED_ANSWERS, STORE_ORG_TYPE } from './actions';

const initialState = {
  firstQuestion: {},
  storedAnswers: [],
  orgType: {
    industry: "",
    employeeCount: ""
  }
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_FIRST_QUESTION:
      return {
        ...state,
        firstQuestion: action.payload,
      };
    case STORE_ORG_TYPE:
      return {
          ...state,
          orgType: action.payload,
      };
    case STORED_ANSWERS:
      return {
        ...state,
        storedAnswers: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../utility/constants';
import { useAlert } from 'react-alert'
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../../utility/utils';

function UserProfile() {
  document.title = "Profile | Firminy Solutions, LLC";
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const returnToReport = localStorage.getItem('returnToReport') === 'true';
  const [userProfile, setUserProfile] = useState({name: "", phone: "", businessname: "", designation: "", city: "", state: "", country: "United States of America"});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const userDetails = getUserDetails(dispatch);
    if(userDetails.authToken === undefined){
      navigate('/home');
      alert.info("Your Session is Expired. Please Log In Again.");
    }else{
      setUserProfile(userDetails);
    }
  },[]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserProfile(prevState => ({
      ...prevState,
      [name]: value
    }));

  };

  const createProfile = async () => {
    if(userProfile.name && userProfile.userid && userProfile.phone && userProfile.businessname && userProfile.authToken){
      setLoading(true);
      try {
        const response = await axios.post(API_URL+"user", userProfile,
          {
            headers: {
              "Auth-Token": userProfile.authToken
            }
          }
        );
        if(response.data){
            const reqId = response.data;
            let err = true;
            for(let i=0;i<8;i++){
              await new Promise(resolve => setTimeout(resolve, 2000));
              const responseStatus = await axios.post(API_URL+"requeststatus", {userid: userProfile.userid, reqid: reqId},{
                headers: {
                  "Auth-Token": userProfile.authToken
                }
              });
              if(responseStatus.data.includes("success")){
                err = false;
                alert.success("Profile Updated!");
                break;
              }else{
                err = true;
              }
            }
            if(err){
              alert.error("Some error occurred while creating profile");
            }else{
              localStorage.setItem("userDetails", JSON.stringify(userProfile));
              await getCustomerId();
            }
        }else{
            alert.error("Some error occurred while creating profile");
        }
      } catch (error) {
        alert.error("Got Some error while creating profile");
        console.error('Error:', error);
      } finally{
        setLoading(false);
      }
    }else{
      alert.error("Required Field is Missing");
    }

  };
  const getCustomerId = async () => {
    let oldUserDetails = JSON.parse(localStorage.getItem("userDetails"))
    try {
      const response = await axios.get(API_URL+"user/"+oldUserDetails.userid, {
        headers: {
          "Auth-Token": oldUserDetails.authToken
        }
      });
      if(response.data.name){
        let newUserDetails = {
          customerid: response.data.customerid,
          userid: oldUserDetails.userid,
          businessname: response.data.businessname,
          city: response.data.city,
          country: response.data.country,
          designation: response.data.designation,
          name: response.data.name,
          phone: response.data.phone,
          role: response.data.role,
          state: response.data.state,
          authToken: oldUserDetails.authToken,
          tokenTime: oldUserDetails.tokenTime,
          industry: response.data.industry,
          employeecount: response.data.employeecount,
          ispremium: response.data.ispremium
        };
        localStorage.setItem("userDetails", JSON.stringify(newUserDetails));
        if(returnToReport){
          await postAnswers(newUserDetails);
        }else{
          navigate('/home');
        }
      }
      
    } catch (error) {
      console.error('Error:', error);
    } finally{
    }

  };

  const postAnswers = async (newUserDetails) => {
    const answersToPost = JSON.parse(localStorage.getItem('userAnswer'));
      const answersToPostWithUserId = answersToPost.map(item => ({
        ...item,
        customerid: newUserDetails.customerid,
        userid: newUserDetails.userid,
        industry: newUserDetails.industry,
        employeecount: newUserDetails.employeecount
      }));
      try {
        const response = await axios.post(API_URL+"bulk/qna", {
          bulkQnA: answersToPostWithUserId
        },
        {
          headers: {
            "Auth-Token": newUserDetails.authToken
        }
        });
        if(response.status===200){
          localStorage.removeItem('userAnswer');
          localStorage.removeItem('returnToReport');
          for(let i=0;i<3;i++){
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
          navigate('/report');
        }else{
          navigate('/assessment');
        }
          
      } catch (error) {
        console.error('Error:', error);
      } finally{
      }
  };

  return (
    <div>

    <section className="step-wrap pt-8 xl:pt-14 relative z-10 relative pb-5 md:pb-10 xl:pb-20">
    {loading ? (
        <Spinner style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) :(
        <div>
        <img alt="" className="floating-bg absolute w-auto left-1/2 -translate-x-1/2 bottom-0 hidden lg:block" src="images/gradient-bg.png"/>
        <img alt="" className="floating-bg absolute w-full left-1/2 -translate-x-1/2 bottom-0 lg:hidden opacity-50" src="images/gradient-bg-mobile.png"/>
        <div className="container mx-auto px-4 sm:px-10 lg:px-16 xl:px-30 2xl:px-40 relative z-10">
          <div className="select-business-category bg-black rounded-xl border-stone-600 border-solid border" >
            <div className="select-business-top p-4 md:p-10 border-stone-600 border-solid border-b">
              <h5 className="text-sm md:text-xl lg:text-2xl lg:text-4xl font-primary font-normal text-white text-left leading-tight mb-2">
                Please Complete Your Profile
              </h5>
              {/* <p className="text-sm md:text-base font-primary font-normal text-stone-500 text-left leading-tight mb-2">
                Please give correct information. Report will be emailed to this
                id.
              </p> */}
              <form>
                <div className="banner-steps flex relative w-full pt-5 lg:pt-10 items-center justify-between flex-wrap">
                  <div className="relative w-full lg:w-1/2 xl:w-1/2 flex-none mb-4 lg:mb-0 xl:pr-4">
                    <label className="flex">
                      <span className="flex flex-none items-end text-white text-sm md:text-lg lg:text-2xl font-primary font-normal pl-3 leading-none">
                        <sup className="text-red-500 absolute left-0 top-4 xl:top-2">*</sup>
                        <span className="flex items-end whitespace-nowrap pr-2">
                          Your Name
                        </span>
                      </span>
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                        <input  type="text" id="name" name="name" value={userProfile.name} onChange={handleChange} className="bg-transparent outline-none border-b border-solid border-stone-500 text-white text-sm lg:text-xl font-primary font-normal w-full"/>
                      </span>
                    </label>
                  </div>
                  <div className="relative w-full lg:w-1/3 xl:w-1/3 flex-none mb-4 lg:mb-0 xl:pr-4">
                    <label className="flex">
                      <span className="flex items-end text-white text-sm md:text-lg lg:text-2xl font-primary font-normal pr-4 pl-3 leading-none">
                        <sup className="text-red-500 absolute left-0 top-4 xl:top-2">*</sup>
                        <span className="flex items-end whitespace-nowrap">
                          Phone
                        </span>
                      </span>
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                        <input type="phone" id="phone" name="phone" value={userProfile.phone} onChange={handleChange} className="bg-transparent outline-none border-b border-solid border-stone-500 text-white text-sm lg:text-xl font-primary font-normal w-full text-center" placeholder="(000) 123-4567"/>
                      </span>
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div className="select-business-bottom p-4 md:p-10">
              <form>
                <div className="banner-steps flex relative w-full pt-1 lg:pt-10 items-center flex-wrap">
                  <div className="relative w-full lg:w-1/2 xl:w-1/2 flex-none mb-4 lg:mb-0 xl:pr-4">
                    <label className="flex">
                      <span className="flex items-end text-white text-sm md:text-lg lg:text-2xl font-primary font-normal pr-4 pl-3 leading-none">
                        <sup className="text-red-500 absolute left-0 top-4 xl:top-2">*</sup>
                        <span className="flex items-end whitespace-nowrap">
                          Business Name
                        </span>
                      </span>
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                        <input type="text" id="businessname" name="businessname" value={userProfile.businessname} onChange={handleChange} className="bg-transparent outline-none border-b border-solid border-stone-500 text-white text-xl font-primary font-normal w-full"/>
                      </span>
                    </label>
                  </div>
                  <div className="relative w-full lg:w-1/2 xl:w-1/2 flex-none mb-4 lg:mb-0 xl:pr-4">
                    <label className="flex">
                      <span className="flex items-end text-white text-sm md:text-lg lg:text-2xl font-primary font-normal pr-4 pl-3 leading-none">
                        <sup className="text-red-500 absolute left-0 top-4 xl:top-2">*</sup>
                        <span className="flex items-end whitespace-nowrap">
                          Role
                        </span>
                      </span>
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                        <input type="text" id="designation" name="designation" value={userProfile.designation} onChange={handleChange} className="bg-transparent outline-none border-b border-solid border-stone-500 text-white text-xl font-primary font-normal w-full"/>
                      </span>
                    </label>
                  </div>
                </div>
                <div className="banner-steps flex relative w-full pt-0 lg:pt-16 items-center flex-wrap">
                  <div className="relative w-full lg:w-1/3 xl:w-1/3 flex-none mb-4 lg:mb-0 xl:pr-4">
                    <label className="flex">
                      <span className="flex items-end text-white text-sm md:text-lg lg:text-2xl font-primary font-normal pr-4 pl-3 leading-none">
                        <sup className="text-red-500 absolute left-0 top-4 xl:top-2">*</sup>
                        <span className="flex items-end whitespace-nowrap">
                          City
                        </span>
                      </span>
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                        <input type="text" id="city" name="city" value={userProfile.city} onChange={handleChange} className="bg-transparent outline-none border-b border-solid border-stone-500 text-white text-xl font-primary font-normal w-full"/>
                      </span>
                    </label>
                  </div>
                  <div className="relative w-full lg:w-1/3 xl:w-1/3 flex-none mb-4 lg:mb-0 xl:pr-4">
                    <label className="flex">
                      <span className="flex items-end text-white text-sm md:text-lg lg:text-2xl font-primary font-normal pr-4 pl-3 leading-none">
                        <sup className="text-red-500 absolute left-0 top-4 xl:top-2">*</sup>
                        <span className="flex items-end whitespace-nowrap">
                          State
                        </span>
                      </span>
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                        <input type="text" id="state" name="state" value={userProfile.state} onChange={handleChange} className="bg-transparent outline-none border-b border-solid border-stone-500 text-white text-xl font-primary font-normal w-full"/>
                      </span>
                    </label>
                  </div>
                  <div className="relative w-full lg:w-1/3 xl:w-1/3 flex-none mb-4 lg:mb-0 xl:pr-4">
                    <label className="flex">
                      <span className="flex items-end text-white text-sm md:text-lg lg:text-2xl font-primary font-normal pr-4 pl-3 leading-none">
                        <sup className="text-red-500 absolute left-0 top-4 xl:top-2">*</sup>
                        <span className="flex items-end whitespace-nowrap">
                          Country
                        </span>
                      </span>
                      <span className="flex-auto lg:pr-2 xl:pr-10">
                        <input type="text" id="country" name="country" value={userProfile.country} onChange={handleChange} className="bg-transparent outline-none border-b border-solid border-stone-500 text-white text-xl font-primary font-normal w-full"/>
                      </span>
                    </label>
                  </div>
                </div>
              </form>
              <div className="button-box flex my-3 md:my-6 justify-center">
                <button onClick={() => createProfile()} className="button-primary text-black custom-btn-primary text-sm md:text-xl font-bold flex items-center justify-center py-3 px-8 transition ease-in-out duration-700 rounded-full">
                  Complete Registration
                  <img alt="" className="inline-block ml-4" src="images/button-arrow-right.svg"/>
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
      )}
      </section>
    </div>
  );
}
export default UserProfile;


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './services/store';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 2000,
  offset: '30px',
  transition: transitions.FADE,
  color: 'red',
  backgroundcolor: 'green'
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}> {/* HERE */}
    <AlertProvider template={AlertTemplate} {...options}>
      <App /> {/* Now, App is wrapped in Provider and hence can read from store */}
    </AlertProvider>
    </Provider>
);

// ReactDOM.render(
//   <StrictMode>
//     <Provider store={store}> {/* HERE */}
//       <App /> {/* Now, App is wrapped in Provider and hence can read from store */}
//     </Provider>
//   </StrictMode>,
//   document.getElementById('root')
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
